import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
SwiperCore.use([Pagination, Autoplay]);

// Import Swiper styles
import 'swiper/css/bundle';

const TrustedBlock = ({ data, styles }) => {
  return (
    <div
      className="w-full h-auto  "
      style={get_dynamic_styles(styles?.main_styles)}
    >
      <div
        className=" relative   flex flex-col  "
        style={get_dynamic_styles(styles?.box_styles)}
      >
        {data?.title && (
          <h2 className=" text-2xl text-black font-semibold">
            {get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'title',
              default_data: data?.title,
            })}
          </h2>
        )}

        {data?.is_show_in_slider != true && (
          <div
            className=" flex   flex-wrap "
            style={get_dynamic_styles(styles?.logos_styles)}
          >
            {data?.logos?.map((logo) => {
              return (
                <img
                  src={logo?.logo_url}
                  className="w-auto h-auto "
                  style={get_dynamic_styles(styles?.image_styles)}
                  alt="logo"
                />
              );
            })}
          </div>
        )}

        {/*  */}
        {data?.is_show_in_slider == true && (
          <Swiper
            slidesPerView={styles?.carousel?.slides_per_view ?? 'auto'}
            spaceBetween={styles?.carousel?.space_between ?? 30}
            autoplay={{
              delay: styles?.carousel?.delay ?? 2500,
              disableOnInteraction: false,
            }}
            className="  w-full h-full  "
            style={get_dynamic_styles(styles?.box_styles)}
          >
            {data?.logos?.map((logo) => {
              return (
                <SwiperSlide
                  className=" w-full   "
                  style={get_dynamic_styles(styles?.card_styles)}
                >
                  <img
                    src={logo?.logo_url}
                    className="w-auto h-auto "
                    style={get_dynamic_styles(styles?.image_styles)}
                    alt="logo"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default TrustedBlock;
