import React, { Fragment, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { getCookie, setCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { TSICONS } from '../../constant/Icons/TSICONS';
import { cn } from '../../utils/classNames';

const CurrencySwitch = ({ id_name = 'header_currency_switch_button' }) => {
  // Currency
  const [currencies, setCurrencies] = useState(TYPE_CONSTANT.CURRENCIES);
  const [selected_currencyCode, setSelectedCCurrencyCode] = useState(
    getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`)
  );
  const [selected_currency_details, setSelectedCurrencyDetails] = useState([]);
  TYPE_CONSTANT.CURRENCY = getCookie(
    `${TYPE_CONSTANT.DOMAIN}_default_currency`
  );

  // Effects
  useEffect(() => {
    setSelectedCCurrencyCode(
      getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`)
    );
  }, [0]);
  useEffect(() => {
    //
    const local_storage_currencies =
      localStorage.getItem('available_currencies') != undefined &&
      JSON.parse(localStorage.getItem('available_currencies'));
    if (local_storage_currencies) {
      setCurrencies(local_storage_currencies);
      setSelectedCurrencyDetails(
        local_storage_currencies?.filter(
          (currency) => currency.code == selected_currencyCode
        )?.[0]
      );
    }
  }, [localStorage.getItem('available_currencies')]);

  // currency change
  const currency_change = (selected_currency_details) => {
    setCookie(
      `${TYPE_CONSTANT.DOMAIN}_default_currency`,
      selected_currency_details?.code
    );
    window.location.reload();
  };

  //current_button_position
  const [dropdown_position, setDropdownPositionState] = useState({
    top: null,
    align_items: 'items-end',
  });
  const buttonRect = document?.getElementById(id_name)?.getBoundingClientRect();
  useEffect(() => {
    const getButtonPosition = () => {
      if (buttonRect) {
        setDropdownPositionState({
          top: buttonRect.top + buttonRect.height + 5,
          align_items:
            buttonRect?.left > buttonRect?.right ? 'items-start' : 'items-end',
        });
      }
    };

    // Call the function to get button position after the component has rendered
    getButtonPosition();
  }, [buttonRect?.x]);

  return (
    currencies?.length > 1 && (
      <div className=" text-right ltr:mr-1 rtl:ml-1 ltr:md:mr-0 rtl:md:ml-0  inline-block  ">
        <Menu
          as="div"
          className={cn(
            ' flex flex-col justify-center  ',
            dropdown_position?.align_items
          )}
        >
          <div>
            <Menu.Button className="inline-flex rtl:flex-row-reverse justify-center items-center w-full text-sm font-medium  p-2 rounded-full  transition duration-400  hover:bg-[#22222213]  text-[#6e686e] ">
              {selected_currency_details?.image_path ? (
                <img
                  src={selected_currency_details?.image_path}
                  alt="flag"
                  className="h-6 w-6 rounded-full  object-cover"
                />
              ) : (
                TSICONS.stack
              )}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              as={'div'}
              className={[
                `fixed  min-w-[120px]  origin-top-left bg-white     rounded-card   shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none   z-50 ltr:right-0 rtl:left-0 ltr:md:right-auto rtl:md:left-auto `,
                currencies?.length > 2 ? 'h-40  overflow-y-auto' : 'h-28 ',
              ].join(' ')}
              style={{
                top: `${dropdown_position?.top}px`,
              }}
            >
              {/* <div className="w-[15px] h-[15px] bg-[#fff] hidden md:block absolute    md:right-0   transform rotate-45  -top-2   ltr:mr-7 rtl:ml-7    border-l border-t border-[rgba(250, 250, 250, 0.93)]  z-[50]" /> */}

              <div className="  py-1">
                <div className="  overflow-auto    scrollbar-thin   scrollbar-track-gray-100  scrollbar-thumb-gray-300   ">
                  {currencies?.map((currency) => {
                    return (
                      <Menu.Item>
                        <button
                          className={[
                            'text-start w-full flex items-center  justify-start hover:text-primary mb-2 shadow-sm px-3 py-2 rounded-sm gap-3 text-base font-medium  leading-none',
                            selected_currencyCode == currency.code
                              ? 'text-primary'
                              : 'text-[#6e686e]',
                          ].join(' ')}
                          onClick={() => currency_change(currency)}
                        >
                          {' '}
                          {currency?.image_path && (
                            <img
                              src={currency?.image_path}
                              alt="flag"
                              className="h-7 w-7 rounded-full  object-cover shadow-sm"
                            />
                          )}
                          {currency.name}
                        </button>
                      </Menu.Item>
                    );
                  })}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    )
  );
};

export default CurrencySwitch;
