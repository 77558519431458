import { useRouter } from 'next/dist/client/router';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';

import NoOrdersItem from '../../components/MyStore/orders/NoOrdersItem/NoOrdersItem';

import CustomLoading from '../../components/Shared/Loading/CustomLoading';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import {
  get_transactions,
  transactionsSelector,
} from '../../store/feature/transactionsSlice';
import TransactionsList from '../../components/Transactions/TransactionsList';
import { Sales_text } from '../../constant/Translation/Sales';

const TransactionsPageLayout = () => {
  const [pageCount, setPageCount] = useState(0);

  const dispatch = useDispatch();
  const router = useRouter();
  const accountId = router.query.account_id;

  const { transactions_list, total_records, page, isFetching } =
    useSelector(transactionsSelector);

  useEffect(() => {
    if (transactions_list == null || page != router.query?.page) {
      dispatch(
        get_transactions({
          bodyParam: {
            page: router.query?.page ? router.query?.page : 1,
            per_page: 30,
            account_id: accountId,
            super_type: 2,
          },
        })
      );
    }
  }, [router]);

  useEffect(() => {
    const totalpage = Math.ceil(total_records / 30);
    if (page === 1 && total_records === 0) {
      setPageCount(total_records);
    }
    if (Number(total_records) > 30) {
      setPageCount(totalpage);
    }
  }, [total_records]);

  const moreTransactions = (data) => {
    router.push({
      query: {
        account_id: router.query.account_id,
        page: Number(data.selected) + 1,
      },
    });
  };

  return (
    <div className=" mt-4">
      {isFetching && <CustomLoading />}
      <div className=" grid  grid-cols-[25%,75%]   sm:grid-cols-[35%,65%] xl:grid-cols-[50%,50%] 2xl:grid-cols-[50%,50%]  mb-11 items-center">
        <h2 className="  text-xl sm:text-3xl   font-bold text-[#042C5C] leading-10">
          {Sales_text.sales.transactions}
        </h2>
      </div>
      {transactions_list === null || transactions_list?.length > 0 ? (
        <div>
          <TransactionsList list={transactions_list} account_id={accountId} />
        </div>
      ) : (
        <div>
          <NoOrdersItem />
        </div>
      )}
      <div className="mt-12   flex justify-center pb-12 ">
        {transactions_list !== null && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            }
            onPageChange={(data) => moreTransactions(data)}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel={
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            }
            renderOnZeroPageCount={null}
            containerClassName=""
            className="relative z-0 inline-flex flex-wrap justify-center rounded-button shadow-sm -space-x-px "
            pageClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center text-sm font-medium"
            pageLinkClassName="px-4 py-2 border"
            previousClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            nextClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            breakLinkClassName="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
            activeLinkClassName="z-10 bg-primary  border-primary text-white relative inline-flex items-center px-4 py-2 border text-md font-semibold"
            disabledLinkClassName=""
            prevPageRel="2"
            forcePage={page - 1}
          />
        )}
      </div>
    </div>
  );
};

export default TransactionsPageLayout;
