import { Add_product_text } from './Translation/AddProduct_text';

export const all_listing_types = [
  { title: Add_product_text.addproduct.physical_product, key: 'listings' },
  { title: Add_product_text.addproduct.event_booking, key: 'events' },
  { title: Add_product_text.addproduct.digital_product, key: 'digital' },
  {
    title: Add_product_text.addproduct.appointment_booking,
    key: 'appointments',
  },
  { title: Add_product_text.addproduct.crowdfunding, key: 'donation' },
  {
    title: Add_product_text.addproduct.information_listing,
    key: 'information_listing',
  },
  {
    title: Add_product_text.addproduct.video_listing,
    key: 'video_listing',
  },
];
