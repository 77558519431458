import Link from 'next/link';
import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../constant/default_styles/ProductDetailsStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import { logout } from '../../store/feature/authSlice';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

const LogoutButton = ({ data, styles }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  return (
    <div
      style={get_dynamic_styles(styles?.main_styles)}
      className="hover:bg-[#f2f4f4]  duration-500  hover:!text-primary cursor-pointer"
      onClick={() => {
        dispatch(logout({ router }));
        window.location.reload();
      }}
    >
      <a
        type="button"
        target={data?.button_target === '_blank' ? '_blank' : '_self'}
        style={{
          display: styles?.box_styles?.display,
          gridTemplateColumns: dynamic_text_convert({
            main_text: styles?.box_styles?.columns_box_percentage,
            check_by: '-1%',
            value: '',
          }),
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {data?.icon && data?.is_icon_in_right != true && (
          <img
            className="w-auto object-cover  "
            style={get_dynamic_styles(styles?.icon_styles)}
            src={data?.icon}
            alt="image"
          />
        )}
        {data?.button_title && (
          <p className="  " style={get_dynamic_styles(styles?.text_styles)}>
            {get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'button_title',
              default_data: data?.button_title,
            })}
          </p>
        )}

        {data?.icon && data?.is_icon_in_right == true && (
          <img
            className="w-auto object-cover  "
            style={get_dynamic_styles(styles?.icon_styles)}
            src={data?.icon}
            alt="image"
          />
        )}
      </a>
    </div>
  );
};

export default LogoutButton;
