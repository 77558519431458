import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const QuoteBlock = ({ data, styles }) => {
  return (
    <div className="w-full   " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        className="px-4  flex flex-col relative  "
        style={get_dynamic_styles(styles?.box_styles)}
      >
        {/*  */}
        <svg
          className={
            styles?.start_quote_icon_styles
              ? ''
              : 'absolute ltr:left-4 rtl:right-4  md:ltr:left-0 md:rtl:right-0 '
          }
          style={
            styles?.start_quote_icon_styles
              ? {
                  display: styles?.start_quote_icon_styles?.display,
                  ...get_dynamic_styles(styles?.start_quote_icon_styles),
                }
              : {
                  top: '15%',
                }
          }
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10.674" cy="31.5978" r="8.674" fill="currentColor" />
          <path
            d="M4.16851 34.3085C0.568806 24.1617 4.59616 12.5767 14.2552 7"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
          />
          <circle cx="36.696" cy="31.5978" r="8.674" fill="currentColor" />
          <path
            d="M30.1906 34.3085C26.5909 24.1617 30.6182 12.5767 40.2773 7"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>

        {/*  */}
        {data?.quote && (
          <p style={get_dynamic_styles(styles?.quote_text_styles)}>
            {get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'quote',
              default_data: data?.quote,
            })}
          </p>
        )}
        {data?.quote_by && (
          <p style={get_dynamic_styles(styles?.quote_by_text_styles)}>
            {get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'quote_by',
              default_data: data?.quote_by,
            })}
          </p>
        )}

        {/*  */}
        <svg
          className={
            styles?.end_quote_icon_styles
              ? ''
              : 'absolute ltr:right-4 rtl:left-4 md:ltr:right-0 md:rtl:left-0 bottom-36 text-opacity-100 items-center '
          }
          style={
            styles?.end_quote_icon_styles
              ? {
                  display: styles?.end_quote_icon_styles?.display,
                  ...get_dynamic_styles(styles?.end_quote_icon_styles),
                }
              : { bottom: '20%' }
          }
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="36.696"
            cy="15.6739"
            r="8.674"
            transform="rotate(-180 36.696 15.6739)"
            fill="currentColor"
          />
          <path
            d="M43.2014 12.9635C46.8011 23.1102 42.7737 34.6953 33.1147 40.272"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
          />
          <circle
            cx="10.674"
            cy="15.6739"
            r="8.674"
            transform="rotate(-180 10.674 15.6739)"
            fill="currentColor"
          />
          <path
            d="M17.1794 12.9635C20.7791 23.1102 16.7518 34.6953 7.09271 40.272"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default QuoteBlock;
