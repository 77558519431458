import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/dist/client/router';
import { saveChange } from './saveChange';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../Shared/PopUp/PopUp';
import CustomLoading from '../Shared/Loading/CustomLoading';
import {
  authSelector,
  logout,
  userAttribute,
  verifyUser,
} from '../../store/feature/authSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import SuccessPopUp from '../Shared/PopUp/Success';
import axios from 'axios';
import ModalBasic from '../Shared/Modal/ModalBesic';
import Alert from '../Shared/PopUp/Alert';
import { EditProfile_text } from '../../constant/Translation/EditProfile_text';
import { dynamic_translation_set } from '../../constant/Translation/translation_set';
import { General_text } from '../../constant/Translation/General';
import PhoneInput from 'react-phone-input-2';
import { Auth_text } from '../../constant/Translation/Auth_text';
import PhoneForm from '../SignUpForm/PhoneForm';
import PopupUI from '../Shared/PopUp/UI/PopupUI';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { validateImageSize } from '../../utils/ImageSizeValidate';
import OPPSetup from '../Opp/OPPSetup';
import UpgradeCard from '../Subscription/UpgradeCard';
import Attributes from './Attributes';

const EditProfile = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  // States
  const [imagePath, setImagePath] = useState(null);
  const [files, setFiles] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [number, setNumber] = useState(null);
  const [dialcode, setDialCode] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [success_message, setSuccess_message] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [attributeData, setAttributeData] = useState([]);

  //
  const [deleteFormOpen, setDeleteFormOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { countries_list, tenant_countries_list, user_details, attributes } =
    useSelector(authSelector);
  const [verifyName, setVerifyName] = useState('');

  useEffect(() => {
    setFirstName(user_details?.first_name);
    setLastName(user_details?.last_name);
    if (user_details?.profile_pic) {
      setImagePath({ path: user_details?.profile_pic });
    }
    if (
      user_details?.dial_code &&
      user_details?.mobile &&
      dialcode == null &&
      number == null
    ) {
      setDialCode(user_details?.dial_code);
      setNumber(`${user_details?.dial_code}${user_details?.mobile}`);
    }
    if (user_details?.country && user_details?.country?.id) {
      setSelectedCountryId(user_details?.country?.id);
    }
    if (user_details?.attributes?.length > 0 && attributeData?.length == 0) {
      user_details?.attributes.filter((attr) => {
        if (
          (attr.field_type === 1 || attr.field_type === 2) &&
          attr.values?.length > 0
        ) {
          setAttributeData((prevState) => [
            ...prevState,
            {
              id: attr.id,
              values: attr.values.map((item) => item.id),
            },
          ]);
        } else if (
          (attr.field_type === 3 || attr.field_type === 4) &&
          attr.values?.length > 0
        ) {
          setAttributeData((prevState) => [
            ...prevState,
            { id: attr.id, values: attr.values.map((item) => item) },
          ]);
        } else if (
          (attr.field_type === 5 ||
            attr.field_type === 13 ||
            attr.field_type === 14) &&
          attr.values?.length > 0
        ) {
          setAttributeData((prevState) => [
            ...prevState,
            {
              values: attr.values,
              id: attr.id,
            },
          ]);
        } else if (
          (attr.field_type === 6 ||
            attr.field_type === 7 ||
            attr.field_type === 8 ||
            attr.field_type === 9 ||
            attr.field_type === 10 ||
            attr.field_type === 11 ||
            attr.field_type === 12) &&
          attr.values?.length > 0
        ) {
          setAttributeData((prevState) => [
            ...prevState,
            {
              values: attr.values,
              id: attr.id,
            },
          ]);
        }
      });
    }
    setUserId(user_details?.id);
  }, [user_details]);

  //
  useEffect(() => {
    dispatch(
      userAttribute({
        prams: {
          type: 'users',
        },
      })
    );
  }, []);

  const closePopUP = () => {
    setShowError(false);
    setError_message('');
    setShowSuccess(false);
    setSuccess_message('');
  };

  return (
    <>
      {showError && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showError && (setShowError(false), setError_message(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp message={error_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {showSuccess && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showSuccess && (setShowSuccess(false), setSuccess_message(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <SuccessPopUp message={success_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {loading && <CustomLoading />}
      <div className=" space-y-5">
        <div className=" bg-white w-full   min-h-[400px] rounded-card shadow-c-xsm px-5 md:px-[38px] pb-10 grid items-center ">
          <OPPSetup />

          <div>
            <div className=" mb-[40px] flex justify-center">
              <input
                id="imageButton"
                type="file"
                value={''}
                className=" hidden"
                accept=".png , .jpg"
                placeholder=""
                onChange={(e) => {
                  if (validateImageSize(e.target.files[0])) {
                    e.target.files.length > 0 &&
                      (setImagePath({
                        id: e.target.files[0].name,
                        path: URL.createObjectURL(e.target.files[0]),
                      }),
                      setFiles(e.target.files[0]));
                  } else {
                    setShowError(true);
                    setError_message(General_text.general.image_size_alert);
                  }
                }}
              />
              <div>
                {imagePath !== null ? (
                  <div className=" relative w-[100px] mt-4">
                    <img
                      src={imagePath.path}
                      className="w-[100px] h-[100px] object-cover"
                      alt="profile image"
                    />
                    <button
                      className=" absolute -top-2 -right-2 text-primary "
                      onClick={() => {
                        setImagePath(null);
                        setFiles(null);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  </div>
                ) : (
                  <button
                    className=" w-[100px]  h-[100px] flex justify-center items-center  mt-3  bg-gray-100 text-sm rounded-button"
                    onClick={() =>
                      document.getElementById('imageButton').click()
                    }
                  >
                    {EditProfile_text.editprofile.add_image}
                  </button>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6">
              <label className="block">
                <input
                  value={firstName}
                  type="text"
                  className="
                    mt-0
                    block
                    w-full
                    px-4
                    rounded-input
                    even:
                    border-2 border-gray-100 shadow-sm transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder={EditProfile_text.editprofile.firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </label>
              <label className="block">
                <input
                  value={lastName}
                  type="text"
                  className="
                    mt-0
                    block
                    w-full
                    px-4
                    rounded-input
                    even:
                    border-2 border-gray-100 shadow-sm transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder={EditProfile_text.editprofile.lastname}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </label>
              {tenant_countries_list?.length > 0 && (
                <div
                  className="  mt-0
                    block
                    w-full
                     
                    rounded-input
                    even:
                    border-2 border-gray-100 shadow-sm transition  duration-700
                    focus:ring-0 focus:border-primary"
                >
                  {tenant_countries_list && (
                    <PhoneInput
                      onlyCountries={tenant_countries_list?.map((country) => {
                        return country.code2.toLowerCase();
                      })}
                      country={
                        tenant_countries_list?.filter((cn) => {
                          if (number && number !== '') {
                            return cn.dial_code === dialcode;
                          } else {
                            return cn.default == true;
                          }
                        }).length > 0 &&
                        tenant_countries_list
                          ?.filter((cn) => cn.default == true)[0]
                          ?.code2.toLowerCase()
                      }
                      value={
                        number && dialcode && number !== '' && dialcode !== ''
                          ? `+${number}`
                          : ''
                      }
                      onChange={(mobile, country, e) => {
                        setNumber(mobile);
                        setDialCode(country.dialCode);
                      }}
                      name={EditProfile_text.editprofile.mobile_number}
                    />
                  )}
                </div>
              )}

              {countries_list?.length > 0 &&
                TYPE_CONSTANT.GENERAL_CONFIGS?.enable_country_in_profile && (
                  <select
                    className="
                    mt-0
                    block
                    w-full
                    px-4
                    rounded-input
                    even:
                    border-2 border-gray-100 shadow-sm transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                    onChange={(e) => setSelectedCountryId(e.target.value)}
                  >
                    <option
                      className="py-2 bg-transparent text-primary"
                      disabled
                      selected
                    >
                      {Auth_text.signup.country_selection}
                    </option>
                    {countries_list &&
                      countries_list.map((country) => {
                        return (
                          <option
                            className="py-2 bg-transparent text-primary"
                            key={country.code2}
                            value={country.id}
                            selected={
                              selectedCountryId == country.id ? true : false
                            }
                          >
                            {country.name}
                          </option>
                        );
                      })}
                  </select>
                )}
            </div>
            {/*  */}
            {attributes?.length > 0 && (
              <Attributes
                attributeData={attributeData}
                setAttributeData={setAttributeData}
                setErrormessage={setError_message}
                setShowError={setShowError}
              />
            )}
            <div className="flex flex-wrap justify-center gap-5 mt-7">
              <button
                className=" btn  btn-primary-fill   w-full sm:w-auto"
                onClick={() => {
                  saveChange(
                    firstName,
                    lastName,
                    imagePath,
                    files,
                    setShowError,
                    setError_message,
                    setLoading,
                    userId,
                    dispatch,
                    setShowSuccess,
                    setSuccess_message,
                    dialcode,
                    number,
                    selectedCountryId,
                    attributeData,
                    attributes
                  );
                }}
              >
                {EditProfile_text.editprofile.submit}
              </button>
            </div>
          </div>
        </div>
        {/* <UpgradeCard /> */}

        {/* Delete Section */}

        <div className="bg-[#ffffff]   border-2 border-red-600 w-full   md:w-[768px] min-h-[200px] rounded-card shadow-c-xsm p-5 md:p-[38px]  ">
          <div>
            <div>
              <h2 className="heading-h2 ">
                {EditProfile_text.editprofile.delete_account}
              </h2>
              <p className="  description-paragraph text-lg font-medium mt-2">
                {EditProfile_text.editprofile.delete_user_desc}
              </p>
            </div>
            <div className="flex flex-wrap justify-end gap-5 mt-7">
              <button
                className="btn-red-fill w-full sm:w-auto px-3 py-2"
                id="edit-profile"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteFormOpen(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
                <span className=" text-sm font-semibold    ml-3  ">
                  {EditProfile_text.editprofile.delete_account}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalBasic
        title="DELETE ACCOUNT"
        id="disable-form-sidebar"
        modalOpen={deleteFormOpen}
        setModalOpen={setDeleteFormOpen}
        closeByOutsideClick={true}
      >
        <section className="min-h-[200px] ">
          <PopupUI
            title={EditProfile_text.editprofile.delete_account}
            right_button={{
              title: EditProfile_text.editprofile.delete_account,
              click: (e) => {
                if (verifyName === `${firstName} ${lastName}`) {
                  setLoading(true);
                  axios
                    .delete('/api/user/delete_user', {
                      data: { userId: userId },
                    })
                    .then((res) => {
                      setLoading(false);
                      dispatch(logout({ router }));
                      window.location.reload();
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.warn(err.response);
                    });
                }
              },
              extra_styles:
                verifyName === `${firstName} ${lastName}`
                  ? 'cursor-pointer'
                  : 'cursor-not-allowed',
              disabled:
                verifyName === `${firstName} ${lastName}` ? false : true,
            }}
            left_button={{
              title: General_text.general.cancel,
              click: (e) => {
                e.stopPropagation();
                setDeleteFormOpen(false);
                setVerifyName('');
              },
            }}
          >
            <div className="  px-10 py-6 space-y-6">
              <div>
                <p className="  description-paragraph text-base text-center mb-3  ">
                  {EditProfile_text.editprofile.delete_user_desc}
                </p>
                <Alert
                  className={`w-full    `}
                  type={'error'}
                  open={true}
                  isOpenFunction={false}
                >
                  <p>{EditProfile_text.editprofile.delete_account_warning}</p>
                </Alert>
              </div>
              <label className="block space-y-1">
                <span className="text-gray-700">
                  {dynamic_translation_set(
                    EditProfile_text.editprofile.enter_account_name,
                    `"${firstName} ${lastName}"`
                  )}
                </span>
                <input
                  type="text"
                  value={verifyName}
                  className="
                    mt-0
                    block
                    w-full
                     px-2
                    border-2 border-gray-400 transition  duration-700
                    focus:ring-0 focus:border-primary
                    rounded-md
                  "
                  placeholder=""
                  onChange={(e) => setVerifyName(e.target.value)}
                />
              </label>
            </div>
          </PopupUI>
        </section>
      </ModalBasic>
    </>
  );
};

export default EditProfile;
