/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import {
  changeOrderStatus,
  changeShipmentStatus,
  get_order_details,
  orderSelector,
} from '../../../store/feature/orderSlice';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import {
  ShipmentStatus,
  changeStatus,
  currentShipmentStatus,
  orderStatus,
} from '../../Shared/Constant/Status';
import { Orders_text } from '../../../constant/Translation/OrdersText';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import OrderTimeline from '../OrderTimeline/OrderTimeline';
import AddressBox3 from '../AddressBox/AddressBox3';
import Modal from '../../Shared/Modal/Modal';
import OutsideClickHandler from 'react-outside-click-handler';
import { useState } from 'react';
import OrderTracking from '../OrderSummary/OrderTracking';
import OrderTracking2 from '../OrderSummary/OrderTracking2';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { dynamic_text_convert } from '../../../utils/dynamicTextConvert';

const DynamicShipmentItemsList = ({ data, styles }) => {
  const { order_details, isChangeStatusFetching } = useSelector(orderSelector);

  return (
    <div
      id="dynamic_shipment_details"
      style={{
        display: styles?.main_styles?.display,
        gridTemplateColumns: dynamic_text_convert({
          main_text: styles?.main_styles?.columns_box_percentage,
          check_by: '-1%',
          value: '',
        }),
        ...get_dynamic_styles(styles?.main_styles),
      }}
    >
      {order_details?.shipments?.map((shipment, index) => {
        const OrderDetails = shipment?.order_details;
        const is_tax_available = OrderDetails?.find(
          (it) => it?.listing_taxes?.length > 0
        );

        return (
          <div
            style={{
              display: styles?.box_styles?.display,
              gridTemplateColumns: dynamic_text_convert({
                main_text: styles?.box_styles?.columns_box_percentage,
                check_by: '-1%',
                value: '',
              }),
              ...get_dynamic_styles(styles?.box_styles),
            }}
          >
            {/* Header */}
            <div
              className={[
                ' w-full grid   pb-2 border-b-2 border-gray-400   z-20',
                is_tax_available
                  ? 'grid-cols-[45%,15%,15%,25%] md:grid-cols-[35%,15%,15%,15%,20%]'
                  : 'grid-cols-[50%,20%,30%] md:grid-cols-[40%,20%,20%,20%]',
              ].join(' ')}
            >
              <p className=" text-sm sm:text-lg text-black font-semibold ">
                {Orders_text.orderdetail.items_summary}
              </p>
              <p className="  text-sm sm:text-lg text-black font-semibold  text-center">
                {Orders_text.orderdetail.qty}
              </p>
              <p className=" hidden md:block text-lg text-black font-semibold  text-center">
                {Orders_text.orderdetail.price}
              </p>

              {is_tax_available && (
                <p className="text-sm sm:text-lg text-black font-semibold  text-center">
                  {Orders_text.orderdetail.tax}
                </p>
              )}
              <p className="text-sm sm:text-lg text-black font-semibold  text-center">
                {Orders_text.orderdetail.total_price}
              </p>
            </div>
            {/* Items */}
            <div className=" w-full flex flex-col gap-2">
              {OrderDetails?.map((item, index) => {
                let listing_details = item?.listing;
                let listing_variant_details = item?.variant
                  ? Object?.keys(item?.variant)?.length > 0
                    ? item?.variant
                    : undefined
                  : undefined;

                let item_details = listing_variant_details
                  ? listing_variant_details
                  : listing_details;

                return (
                  <Link
                    key={Math.random()}
                    href={
                      item?.listing?.slug
                        ? `/l/${item?.listing?.slug}`
                        : `/l/${
                            item?.listing?.id
                          }-${item?.listing?.title.replace(
                            /[ /?,#=]+/g,
                            '-'
                          )}?id=true`
                    }
                  >
                    <a
                      className={[
                        ' w-full  grid    items-center cursor-pointer',
                        is_tax_available
                          ? 'grid-cols-[45%,15%,15%,25%] md:grid-cols-[35%,15%,15%,15%,20%]'
                          : 'grid-cols-[50%,20%,30%] md:grid-cols-[40%,20%,20%,20%]',
                        index !== OrderDetails.length - 1 &&
                          'border-b-2 border-gray-400 border-opacity-20',
                      ].join(' ')}
                    >
                      <div
                        className=" flex items-center ltr:pl-1 rtl:pr-1"
                        key={item.id}
                      >
                        {item_details?.images?.length > 0 && (
                          <img
                            src={getThumbnailImage(item_details?.images[0])}
                            className="h-auto w-[50px] object-cover object-left-top"
                            alt="image"
                            onError={(e) =>
                              (e.target.src = item_details?.images[0])
                            }
                          />
                        )}
                        <div className="flex flex-col gap-2 ltr:ml-3 rtl:mr-3">
                          <p className=" text-sm font-semibold  ">
                            {listing_details.title}{' '}
                            {listing_variant_details &&
                              listing_variant_details?.title &&
                              `(${listing_variant_details.title})`}
                          </p>
                          {item?.listing?.fulfilled_by_platform &&
                            TYPE_CONSTANT.SHIPPING_CONFIGS
                              .fulfillment_by_platform_enabled && (
                              <img
                                className=" w-auto h-auto max-w-[80px]"
                                src={
                                  TYPE_CONSTANT.SHIPPING_CONFIGS
                                    .fulfilled_by_platform_icon_path
                                }
                                alt="image"
                              />
                            )}
                          {!item?.listing?.fulfilled_by_platform &&
                            TYPE_CONSTANT.SHIPPING_CONFIGS
                              .fulfillment_by_platform_enabled && (
                              <img
                                className="w-auto h-auto  max-w-[80px]"
                                src={
                                  TYPE_CONSTANT.SHIPPING_CONFIGS
                                    .fulfilled_by_seller_icon_path
                                }
                                alt="image"
                              />
                            )}
                        </div>
                      </div>
                      <div>
                        <p className=" text-sm font-semibold  text-center">
                          X {item.quantity}
                        </p>
                      </div>
                      <div className=" hidden md:block">
                        <p className=" text-sm font-semibold flex items-center flex-wrap  justify-center text-center">
                          {item.offer_price.formatted}
                        </p>
                      </div>
                      {item?.listing_taxes?.length > 0 && (
                        <div className=" flex  flex-col">
                          {item?.listing_taxes?.map((tax) => {
                            return (
                              <p className=" text-sm font-semibold flex items-center flex-wrap  justify-center text-center">
                                {tax?.amount?.formatted}
                              </p>
                            );
                          })}
                        </div>
                      )}
                      <div>
                        <p className=" text-sm font-semibold  flex flex-wrap justify-center items-center text-center">
                          {item.offer_price.formatted}
                        </p>
                      </div>
                    </a>
                  </Link>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DynamicShipmentItemsList;
