import { useRouter } from 'next/dist/client/router';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  myAccountListings,
  myStore,
  storeSelector,
} from '../../store/feature/storeSlice';
import NoProducts from '../../components/MyStore/NoProducts/NoProducts';
import NoStore from '../../components/MyStore/NoStore/NoStore';
import AccountProfile from '../../components/MyStore/MyStoreDetails/AccountProfile/AccountProfile';
import AccountTab from '../../components/MyStore/MyStoreDetails/AccountTabs/AccountTab';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { getCookie } from 'cookies-next';

const MyStorePageLayout = () => {
  const [pageCount, setPageCount] = useState(0);

  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    let ignore = false;
    let account_id = getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`);
    if (!ignore && account_id) {
      ignore = true;
      dispatch(
        myAccountListings({
          prams: {
            page: router.query?.page ? router.query?.page : 1,
            per_page: 30,
            account_id: account_id,
            include: 'attributes',
          },
        })
      );
    }

    return () => {
      ignore = false;
    };
  }, [router.query?.page]);

  const {
    my_stores,
    my_store_listings,
    my_store_listings_page,
    my_store_listings_total_records,
  } = useSelector(storeSelector);

  useEffect(() => {
    if (my_stores?.length > 0) {
      const totalpage = Math.ceil(my_store_listings_total_records / 30);
      if (Number(my_store_listings_total_records) > 30) {
        setPageCount(totalpage);
      }
    }
  }, [my_stores, my_store_listings_total_records]);

  return (
    <div>
      {my_stores !== null &&
        (my_stores.length > 0 ? (
          <div>
            <div>
              <AccountProfile account_details={my_stores[0]} />
            </div>
            <div className=" mt-6">
              {my_store_listings !== null &&
                (my_store_listings?.length > 0 ? (
                  <>
                    <AccountTab account_details={my_stores[0]} />
                  </>
                ) : (
                  <div>
                    <NoProducts my_stores={my_stores} />
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className=" ">
            <NoStore />
          </div>
        ))}
    </div>
  );
};

export default MyStorePageLayout;
