import React, { memo } from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../../constant/default_styles/HomeDefaultStyles';
import { getThumbnailImage } from '../../Shared/Constant/Constant';

const ListingRawImage = ({ styles, images, data }) => {
  const image_type = data?.image?.type;
  const image = images[0];
  return (
    images?.length > 0 && (
      <div style={get_dynamic_styles(styles?.main_styles)}>
        <div
          style={get_dynamic_styles_with_default_value(
            styles?.box_styles,
            HomeCardBoxDefaultStyles
          )}
        >
          <img
            style={get_dynamic_styles(styles?.image_styles)}
            src={image}
            onError={(e) => {
              e.target.src = images[0];
            }}
            alt="image"
          />
        </div>
      </div>
    )
  );
};

export default memo(ListingRawImage);
