export const EXTRA_QUERY_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'srsltid',
  'gclid',
  'fbclid',
  'msclkid',
  'cid',
  'eid',
  'ref',
  'aff_id',
  'partner',
  'subid',
  'sid',
  'uid',
  'cid',
  'vid',
  'product_id',
  'category',
  'coupon',
  'cart_id',
  'ad_id',
  'adset_id',
  'creative_id',
  'placement',
  'campaign_id',
  'lat',
  'lng',
  'geo',
  'zip',
  'token',
  'key',
  'lang',
  'theme',
  'version',
];
