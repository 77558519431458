import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { General_text } from '../../../constant/Translation/General';

const Home_filter = () => {
  const router = useRouter();
  const category_id = router?.query?.category_id;
  const attribute_value_id = router?.query?.attribute_value_id;
  const [activeParent, setActiveParent] = useState([]);

  const [account_categories, setAccount_categories] = useState(null);
  const [account_attributes, setAccount_attributes] = useState(null);
  const [is_show_more, setIs_show_more] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState(null);
  const [selectedAtValues, setSelectedAtValues] = useState([]);

  useEffect(() => {
    axios
      .get('/api/categories', { params: { type: 'accounts', parent: 0 } })
      .then((res) => {
        setAccount_categories(res.data?.categories);
      });

    axios
      .get('/api/attributes', { params: { type: 'accounts' } })
      .then((res) => {
        setAccount_attributes(res.data.attributes);
      });
  }, [0]);

  // filter by category
  const filter_by_category = (id) => {
    if (id !== 'default') {
      setSelectedCategories(id);
      router.push({
        query: {
          ...router.query,
          category_id: id,
        },
      });
    } else {
      setSelectedCategories(null);
      const queries = { ...router.query };
      delete queries.category_id;
      router.push({
        query: { ...queries },
      });
    }
  };

  // filter by attribute
  const filter_by_attribute_value = (id) => {
    const check = selectedAtValues?.find((at) => at == id);
    if (check === undefined) {
      setSelectedAtValues([...selectedAtValues, id]);
      router.push({
        query: {
          ...router.query,
          attribute_value_id: [...selectedAtValues, id].toString(''),
        },
      });
    } else {
      const filter = selectedAtValues?.filter((at) => at != id);
      setSelectedAtValues(filter);
      if (filter.length > 0) {
        router.push({
          query: {
            ...router.query,
            attribute_value_id: [...filter].toString(''),
          },
        });
      } else {
        const queries = { ...router.query };
        delete queries.attribute_value_id;
        router.push({
          query: { ...queries },
        });
      }
    }
  };

  // current_values
  const current_values = (attribute) => {
    let selected_values = [];

    for (let i = 0; i < attribute?.values?.length; i++) {
      const element = attribute?.values[i];
      if (selectedAtValues?.includes(element?.id)) {
        selected_values.push({
          value: element?.id,
          label: element?.name,
        });
      }
      if (i == attribute?.values?.length - 1) {
        return selected_values;
      }
    }
  };

  return (
    <div className="px-5">
      <p className=" caption font-medium font-title_font">Filter by:</p>
      <hr className="mt-6" />
      <div className="mt-4">
        <fieldset>
          <legend className="paragraph font-medium mb-4">Specialties</legend>
          <div className="flex items-center gap-4 mb-4">
            <input
              className=" w-6 h-6   text-primary   focus:ring-primary"
              type="radio"
              name="filter_by_category"
              value="default"
              id="default"
              onClick={() => filter_by_category('default')}
              checked={!category_id && true}
            />
            <label htmlFor={'default'} className="body">
              All Specialist
            </label>
          </div>
          {account_categories?.map((category, index) =>
            index < 3 ? (
              <div className="flex items-center gap-4 mb-4" key={category.id}>
                <input
                  className=" w-6 h-6   text-primary focus:ring-primary"
                  id={category.id}
                  type="radio"
                  name="filter_by_category"
                  value={category.id}
                  onClick={() => filter_by_category(category.id)}
                  checked={category_id == category.id && true}
                />
                <label htmlFor={category.id} className="body">
                  {category.name}
                </label>
              </div>
            ) : (
              is_show_more && (
                <div className="flex items-center gap-4 mb-4" key={category.id}>
                  <input
                    className=" w-6 h-6   text-primary focus:ring-primary"
                    id={category.id}
                    type="radio"
                    name="filter_by_category"
                    value={category.id}
                    onClick={() => filter_by_category(category.id)}
                    checked={category_id == category.id && true}
                  />
                  <label htmlFor={category.id} className="body">
                    {category.name}
                  </label>
                </div>
              )
            )
          )}
        </fieldset>
        <button
          className=" w-full text-primary mt-4 font-medium    flex justify-start px-2 focus:ring focus:ring-primary"
          onClick={() => setIs_show_more(!is_show_more)}
        >
          {is_show_more ? 'Show less' : 'Show more'}
        </button>

        <hr className="my-4" />

        {/* attributes */}
        <div>
          {account_attributes?.map((attribute) => {
            return (
              attribute?.show_in_filter && (
                <ul className="" key={Math.random()}>
                  {!attribute?.values?.length > 0 ? (
                    <li
                      className="flex items-center gap-4 mb-4"
                      key={attribute.id}
                    >
                      <input
                        className=" w-6 h-6   text-primary focus:ring-primary"
                        id={attribute.id}
                        type="checkbox"
                        value={attribute.id}
                      />
                      <label htmlFor={attribute.id} className="body">
                        {attribute.name}
                      </label>
                    </li>
                  ) : (
                    <li className="  " key={attribute.id}>
                      <label
                        htmlFor={attribute.id}
                        className="paragraph font-semibold mb-2"
                      >
                        {attribute.name}
                      </label>

                      <MultiSelect
                        className="filter_multi_select mt-2 "
                        options={attribute?.values?.map((value, index) => {
                          return {
                            value: value?.id,
                            label: value.name,
                          };
                        })}
                        value={current_values(attribute)}
                        onChange={(e) => {
                          setSelectedAtValues(
                            e.map((value) => value?.value && value?.value)
                          );
                        }}
                        labelledBy="Select"
                        overrideStrings={{
                          allItemsAreSelected:
                            General_text.general.allItemsAreSelected,
                          clearSearch: General_text.general.clearSearch,
                          clearSelected: General_text.general.clearSelected,
                          noOptions: General_text.general.noOptions,
                          search: General_text.general.search,
                          selectAll: General_text.general.selectAll,
                          selectAllFiltered:
                            General_text.general.selectAllFiltered,
                          selectSomeItems: General_text.general.selectSomeItems,
                          create: General_text.general.create,
                        }}
                      />
                    </li>
                  )}
                </ul>
              )
            );
          })}
        </div>
        <button
          className=" w-1/2 p-1 text-primary mt-4 font-normal    flex justify-start px-2 focus:ring focus:ring-primary transition duration-300 hover:bg-primary hover:text-white"
          onClick={() => router.push({ query: {} })}
        >
          Clear filters
        </button>
      </div>
    </div>
  );
};

export default Home_filter;
