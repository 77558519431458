export var Listing_text = {
  product: {
    comment_title: 'Comments',
    negotiate_offer_made: 'You made an offer',
    close_offer: 'Close offer',
    accepted_offer: 'Accepted offer',
    offer_rejected: 'Offer rejected',
    offer_closed_by_you: 'Offer closed by you',
    negotiate_enter_message: 'Enter Message',
    submit_offer: 'Submit Offer',
    buyer_negotiations_list: 'Buyer Negotiations List',
    all_negotiations_list: 'All Negotiations List',
    buyer_made_offer: 'Buyer made an offer',
    negotiate_offer: 'Offer',
    offer_closed_by_buyer: 'Offer closed by buyer',
    multi_seller_error: 'You cannot add items from multiple seller',
    time_expired: 'Time has expired',
    expire_in_hours: 'Expire in {value} hours',
    expire_in_minutes: 'Expire in {value} minutes',
    expire_in_few_seconds: 'Expire in  few seconds',
    listing_added_in_cart: 'Listing added in cart',
    //
    donated: 'contributed a {value} donation, embodying kindness and support.',
    anonymous_donner: 'An anonymous benefactor',
    select_date: 'Select a date',
    listing_tax: 'Tax Details',
    added_tax: '+ Tax {value} %',

    fund_now: 'Donate Now',
    goal_complete: 'Campaign Completed',
    listing_expired: 'Campaign Expired',
    max_fund_amount: 'Available amount to donate is {value}',
    min_fund_amount_alert: 'Donation should be more than zero',
    max_fund_amount_alert: 'Donation should not be  more than maximum amount',
    variant_not_available: 'No available combinations',
    donation_list: 'Donation list',
    donation_list_not_available: 'Donation list not available',
    out_of_stock: 'OUT OF STOCK',
    apply_now: 'Apply Now',
    visit_website: 'Visit website',
    no_product_found: ' Listing not found',
    your_review: 'Your review',
    select_variant: 'Select Variant',
    negotiate_add_success: 'Negotiation added successfully',
    real_time_offers: 'Real Time offers',
    view_all_offers: 'View All offers',

    question: 'Question:',
    question_title: 'Customer Questions & Answers',
    answer: 'Answer',
    answer_not_found: '-Not answered yet-',
    ask_question_section_title: "Do'nt see the answer you're looking for?",
    ask_question_button_title: ' Post your question',
    question_form_title: ' Post your question',
    answer_form_title: ' Answer the question',
    question_form_desc:
      'Your question will be answered by seller of this listing.',
    answer_the_question: 'Answer',

    followers: 'followers',
    stock_text: 'Only {value} products in stock',
    variant_validation: 'Select one variant',
    donate: 'Donate',
    install: 'Install',
    category: 'Category',
    see_all: 'See ALl',
    more_listing_from_account_title: 'More from this account',
    book_now: 'Book Now',
    view_schedule: 'View Schedule',
    schedule_title: ' Schedule Date & Time',
    barcode_preview: 'Preview Barcode',
    download_png: 'Download PNG',
    download_jpeg: 'Download JPEG',
    barcode_url: 'Barcode URL',
    sku: 'SKU',
    by: 'By',
    shipping_options: '  Shipping Options',
    activate: 'Activate',
    deactivate: 'Deactivate',
    'makeoffer.alert_less_amount':
      'Negotiated price should not be less than Listing price <price>',
    'makeoffer.enter_amount': 'Enter Amount',
    'makeoffer.make_offer': 'Make Offer',
    add_to_cart: 'Add to cart',
    add_to_cart_success: 'Added to cart successfully',
    added_successfully: 'Item has been added successfully',
    adding_to_cart: 'Adding to cart',
    alert_message_cart_clear_failed: `Couldn't able to clear cart at the moment. Try again`,
    alert_product_delete: 'Are you sure.you want to delete this Listing?',
    alert_success_product_delete: 'Listing deleted successfully',
    all_reviews: 'All Reviews',
    approved: 'Approved',
    available: 'Available',
    buy_now: 'Buy Now',
    chat: 'Chat',
    clear_cart: 'Clear Cart',
    clear_cart_info:
      'There are items in your cart from another seller. Do you want to clear your cart and add this item?',
    contiue: 'Continue',
    copy_link: 'Copy Link',
    link_copied: 'Link Copied',
    delete: 'Delete',
    delete_confirmation:
      'Do you really want to delete this listing? This process cannot be undone.',
    delete_product: 'Delete Listing',
    description: 'Description',
    details: 'Details',
    directions: 'Directions',
    edit: 'Edit',
    error_alert_product_delete: `Couldn't delete a Listing at a moment. Try again later`,
    follow: 'Follow',
    following: 'Following',
    go_to_cart: 'Go to Cart',
    go_to_store: 'Go to Account',
    helpful: 'Helpful',
    make_offer: 'Make Offer',
    make_offer: 'Make Offer',
    make_offer_price_zero_validation:
      'Amount should be greater than {value} 0.00',
    make_offer_price_zero_validation:
      'Amount should be greater than {value} 0.00',
    mark_as_sold: 'Mark as Sold',
    negotiate: 'Negotiate',
    negotiate: 'Negotiate',
    negotiate: 'Negotiate',
    negotiate_accept: 'Accept',
    negotiate_accept: 'Accept',
    negotiate_accepted: 'Accepted',
    negotiate_accepted: 'Accepted',
    negotiate_buy_at: 'Buy at {value}',
    negotiate_buy_at: 'Buy at {value}',
    negotiate_decline: 'Decline',
    negotiate_declined: 'Declined',
    negotiate_enter_amount: 'Enter Amount',
    no_reviews_found: 'No reviews found',
    open_google_maps: 'Open in Google Maps',
    open_maps: 'Open in Maps',
    rating: 'rating',
    rating_with_photo: 'With Photo',
    ratings: 'ratings',
    read_all_reviews: 'Read All Reviews',
    refreshing: 'Refreshing',
    rejected: 'Rejected',
    report: 'Report',
    report_reason: 'Reason',
    report_reason_empty_error: 'Reason cannot be empty',
    report_success: 'Listing reported successfully',
    reporting: 'Reporting',
    reviewed: 'Reviewed',
    reviews: 'reviews',
    share: 'Share',
    share_description:
      'Check out this listing on {value}.Buy, Sell, Chat to get what you wanted.',
    similar_product_title: 'You may like this',
    sold: 'Sold',
    sold_out: 'Sold out',
    tags: 'Tags',
    under_review: 'Under Review',
    updated_successfully: 'Item has been updated successfully',
    view: 'View',
    whatsapp: 'WhatsApp',
    waiting_for_approval: 'waiting for Approval',
    chat_to_buy: 'Chat to buy',
    only: 'Only',
    left: 'Left',
  },
  productlist: {
    to: 'to',
    cities_page_title: 'Explore Products from your City',

    clear_filter: 'Clear Filter',
    filter: 'Filter',
    filter_maximum: 'Maximum',
    filter_minimum: 'Minimum',
    filter_price_range: 'Price Range',
    header_title: 'Listings',
    modify_filter: 'Modify Filter',
    no_products_found: 'No Listings found',
    product: 'Listing',
    under_review: 'Under Review',
    'purchase,subscription': 'subscription',
  },
  productschedule: {
    schedule_end_date: 'End Date',
    stock: 'Total Stock',
    select_schedule: 'Select Schedule',
    add_schedule: 'Add Schedule',

    //
    schedule_start_date: 'Start Date',
    add_schedule_button_title: 'Add Schedule',
    no_schedule_available_alert: 'No schedule available.',
    edit_schedule: 'Edit schedule',
    // From CSV
    select_date_time: 'Select Date & Time',
    on_schedule_available: 'No schedule available',
    today: 'Today',
    continue_booking: 'Continue Booking',
    mins: 'Mins',
  },
  producttime: {
    select_end_date: 'Select end date',

    //
    title: 'Timing',
    start_time: 'Start Time',
    end_time: 'End Time',
    select_time: 'Select Time',
    repeat: 'Repeat',
    select_repeat: 'Select Repeat',
    daily: 'Daily',
    weekdays: 'Weekdays (Mon - Fri)',
    weekend: 'Weekend (Sat - Sun)',
    custom: 'Custom',
    set: 'Set',
    set_days: 'Set Days',
    sun: 'Sun',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    done: 'Done',
    end_time_greater_start_error: 'End time must be greater then start time',
    select_start_time: 'Please select start time',
    select_end_time: 'Please select end time',
    select_date: 'Select start date',
  },
};
