// import { FileINputType, TextINputType } from '../../types/FormFieldTypes';
// import { cn } from '../../utils/classNames';

import AllIcons from '../../components/Shared/Constant/Icons/AllIcons';
import { General_text } from '../../constant/Translation/General';

const VideoPicker = ({
  title,
  current_value,
  set_new_value,
  note,
  is_required = true,
  is_disabled = false,
  onClick,
  onKeyDown,
  title_icon,
  styles,
  accepted_file = '.mp4,.avi,.mkv,.mov,.wmv,.flv,.mpeg,.webm,.3gp,.ogv,.m4v,.rmvb,.ts,.hevc,.h264',
  add_file_title,
  max_size,
  min_size,
  setIsError,
  setErrorMessage,
  delete_file,
  isOptional,
  tooltip_text,
  button_icon,
}) => {
  return (
    <div
      //   className={cn(' w-full flex flex-col gap-1 ', styles?.component_styles)}
      className={' w-full flex flex-col gap-3 '}
    >
      <div className=" w-full flex items-start justify-start   gap-1   ">
        {/* Title */}
        {title && (
          <p
            //   className={cn(
            //     ' text-gray-700  text-lg text-start flex items-center justify-start gap-2',
            //     styles?.title_styles
            //   )}
            className={
              isOptional
                ? 'flex-none text-gray-700  text-lg '
                : "flex-none  text-lg  relative after:content-['*'] text-red-500 after:-top-[5px] after:-right-[10px] "
            }
          >
            {title_icon && <span>{title_icon}</span>}
            {title}
          </p>
        )}

        {tooltip_text && (
          <button className="tooltip">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6  "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>

            <span className="top_hover text-primary">{tooltip_text}</span>
          </button>
        )}
      </div>

      <input
        id="video-fileButton"
        type="file"
        value={''}
        className="hidden"
        accept={accepted_file}
        placeholder=""
        onChange={(e) => {
          if (e.target.files?.length) {
            const fix_type = accepted_file.split(',');
            const file_name = e.target.files[0].name;
            const file_size = e.target.files[0].size / 1024 / 1024;

            if (
              fix_type.includes(
                `.${file_name
                  .split('.')
                  [file_name.split('.').length - 1].toLowerCase()}`
              )
            ) {
              if (file_size <= max_size && file_size >= min_size) {
                set_new_value(e.target.files);
              } else {
                if (file_size > max_size) {
                  setIsError(true);
                  setErrorMessage(
                    `File size exceeds maximum limit ${max_size} MB`
                  );
                  return false;
                } else if (file_size < min_size) {
                  setErrorMessage(`Minimum file size is ${min_size} KB`);
                }
              }
            } else {
              setIsError(true);
              setErrorMessage(
                `Please select a valid video file. Supported formats: ${accepted_file},currently selected file is ${file_name}`
              );
              return false;
            }
          }
        }}
      />

      {current_value !== null && current_value !== undefined && (
        <div className=" relative  flex w-full flex-col items-center border-2 border-dashed border-primary py-2">
          <p>{current_value}</p>
          <button
            className="  text-sm font-normal text-red-400 "
            onClick={() => delete_file()}
          >
            {General_text.general.cancel}
          </button>
        </div>
      )}

      {(current_value == null || current_value == undefined) && (
        <button
          //   className={cn(
          //     ' mt-3 flex w-full items-center justify-center rounded-buttonborder-2 border-dashed  border-primary  bg-gray-100  py-10 text-base  font-semibold ',
          //     styles?.add_button_styles
          //   )}
          className={
            '  flex flex-col w-full items-center justify-center   border-2 border-dashed  border-primary    py-4 text-sm  font-semibold  text-gray-600 gap-3'
          }
          onClick={() => document.getElementById('video-fileButton').click()}
        >
          {AllIcons.video_icon}
          {add_file_title}
        </button>
      )}
    </div>
  );
};

export default VideoPicker;
