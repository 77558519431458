import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import CartIcon from '../../../assets/Images/Cart/Nocart.png';

const Checkout_success = ({ order_details }) => {
  return (
    order_details && (
      <div className="  min-h-screen    ">
        <div className=" mt-8 w-full  md:w-[600px] min-h-[300px] py-10 bg-white shadow-c-sm  flex flex-col justify-center items-center mx-auto">
          <p className="text-2xl font-semibold text-primary my-4 mx-auto text-center">
            Order Reference : #{order_details?.reference_number}
          </p>
          <div>
            <Image
              src={CartIcon}
              width={250}
              height={250}
              alt="No Item"
              objectFit="contain"
            />
          </div>
          <p className="text-base font-semibold text-primary mt-4">
            Thank you. Your Order is successful.
          </p>
          <div className="flex flex-col xs:flex-row  justify-between items-center gap-5 my-5">
            <Link href="/" passHref={true}>
              <button className="bg-primary rounded-button mt-8 px-4 py-2  text-base text-white font-medium ">
                Back To Home
              </button>
            </Link>
            <Link href="/orders" passHref={true}>
              <button className="bg-primary rounded-button mt-8 px-4 py-2  text-base text-white font-medium ">
                Go to Orders
              </button>
            </Link>
          </div>
        </div>
      </div>
    )
  );
};

export default Checkout_success;
