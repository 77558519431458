import axios from 'axios';
import { useRouter } from 'next/router';
import React, { memo } from 'react';
import Link from 'next/link';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';

const DynamicTitleDescriptionButton = ({ data, styles }) => {
  return (
    <div
      className="w-full    flex  items-center justify-center"
      style={get_dynamic_styles(styles?.main_styles)}
    >
      <div
        className=" flex flex-col   "
        style={get_dynamic_styles(styles?.box_styles)}
      >
        {/* title */}
        {data?.title && (
          <h1
            className="  "
            style={get_dynamic_styles(styles?.heading_text_styles)}
          >
            {get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'title',
              default_data: data?.title,
            })}
          </h1>
        )}

        {/* description */}
        {data?.description && (
          <p
            className="  "
            style={get_dynamic_styles(styles?.description_text_styles)}
          >
            {get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'description',
              default_data: data?.description,
            })}
          </p>
        )}

        {/* button */}
        {data?.button_title && data?.url && (
          <Link href={data?.url ?? '#'}>
            <a
              className="     rounded-button  flex items-center justify-center  gap-2 "
              type="button"
              target={data?.button_target ?? '_blank'}
              style={get_dynamic_styles(styles?.button_styles)}
            >
              {data?.icon && data?.is_icon_in_right != true && (
                <img
                  className="w-auto object-cover  "
                  style={get_dynamic_styles(styles?.button_icon_styles)}
                  src={data?.icon}
                  alt="image"
                />
              )}
              {get_data_by_current_language({
                data_list: data?.translated_data,
                key_name: 'button_title',
                default_data: data?.button_title,
              })}

              {data?.icon && data?.is_icon_in_right == true && (
                <img
                  className="w-auto object-cover  "
                  style={get_dynamic_styles(styles?.button_icon_styles)}
                  src={data?.icon}
                  alt="image"
                />
              )}
            </a>
          </Link>
        )}
      </div>
    </div>
  );
};

export default memo(DynamicTitleDescriptionButton);
