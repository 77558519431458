/* eslint-disable @typescript-eslint/no-var-requires */

import { Orders_text } from '../../../constant/Translation/OrdersText';

import { slug as SlugChecker } from 'github-slugger';

export const orderStatus = (id) => {
  if (id === 1) {
    return 'Incomplete';
  }
  if (id === 2) {
    return 'Confirmed';
  }
  if (id === 3) {
    return 'In progress';
  }
  if (id === 4) {
    return 'Shipped';
  }
  if (id === 5) {
    return 'Customer unreachable';
  }
  if (id === 6) {
    return 'Out for delivery';
  }
  if (id === 7) {
    return 'Returned';
  }
  if (id === 8) {
    return ' Return confirmed';
  }
  if (id === 9) {
    return 'Delivered';
  }
  if (id === 10) {
    return 'Delivery confirmed';
  }
  if (id === 11) {
    return 'Return initiated';
  }
  if (id === 12) {
    return 'Return picked';
  }
  if (id === 13) {
    return 'Return confirmed';
  }
  if (id === 14) {
    return 'Return disputed';
  }
  if (id === 15) {
    return 'Canceled by seller ';
  }
  if (id === 16) {
    return 'Canceled by customer';
  }
  if (id === 17) {
    return 'Ready for pickup';
  }
  if (id === 18) {
    return 'Order Completed';
  }
  if (id === 19) {
    return 'Order Confirmed';
  }
  if (id === 20) {
    return 'Arrived at customer location';
  }
  if (id === 21) {
    return 'Canceled By Admin';
  }
};

// Change Status
// export const changeStatus = (id) => {
//   if (id === 1) {
//     return Orders_text.orderdetail.incomplete;
//   }
//   if (id === 2) {
//     return Orders_text.orderdetail.order_confirmed;
//   }
//   if (id === 3) {
//     return Orders_text.orderdetail.order_in_progress;
//   }
//   if (id === 4) {
//     return Orders_text.orderdetail.shipped;
//   }
//   if (id === 5) {
//     return Orders_text.orderdetail.unreachable;
//   }
//   if (id === 6) {
//     return Orders_text.orderdetail.out_for_delivery;
//   }
//   if (id === 7) {
//     return Orders_text.orderdetail.returned;
//   }
//   if (id === 8) {
//     return Orders_text.orderdetail.order_return_confirmed;
//   }
//   if (id === 9) {
//     return Orders_text.orderdetail.order_delivered;
//   }
//   if (id === 10) {
//     return Orders_text.orderdetail.orderdetail_delivery_confirmed;
//   }
//   if (id === 11) {
//     return Orders_text.orderdetail.return_initiated;
//   }
//   if (id === 12) {
//     return Orders_text.orderdetail.return_pickedup;
//   }
//   if (id === 13) {
//     return Orders_text.orderdetail.order_return_confirmed;
//   }
//   if (id === 14) {
//     return Orders_text.orderdetail.order_return_disputed;
//   }
//   if (id === 15) {
//     return Orders_text.orderdetail.cancel_order;
//   }
//   if (id === 16) {
//     return Orders_text.orderdetail.cancel_order;
//   }
//   if (id === 17) {
//     return Orders_text.orderdetail.ready_for_pickup;
//   }
//   if (id === 18) {
//     return Orders_text.orderdetail.order_completed;
//   }
//   if (id === 19) {
//     return Orders_text.orderdetail.order_confirmed;
//   }
//   if (id === 20) {
//     return Orders_text.orderdetail.arrived_at_customer_location;
//   }
//   if (id === 21) {
//     return Orders_text.orderdetail.cancel_by_admin;
//   }
// };

export const changeStatus = (id) => {
  switch (id) {
    case 1:
      return Orders_text.orderdetail.incomplete;
      break;
    case 2:
      return Orders_text.orderdetail.order_place;
      break;
    case 3:
      return Orders_text.orderdetail.order_in_progress;
      break;
    case 4:
      return Orders_text.orderdetail.ship;
      break;
    case 5:
      return Orders_text.orderdetail.order_deliver;
      break;

    case 6:
      return Orders_text.orderdetail.cancel_by_customer;
      break;
    case 7:
      return Orders_text.orderdetail.cancel_by_admin;
      break;
    case 8:
      return Orders_text.orderdetail.order_complete;
      break;

    default:
      break;
  }
};

// Current order Status
// export const changeStatus = (id) => {
//   if (id === 1) {
//     return Orders_text.orderdetail.incomplete;
//   }
//   if (id === 2) {
//     return Orders_text.orderdetail.order_confirmed;
//   }
//   if (id === 3) {
//     return Orders_text.orderdetail.order_in_progress;
//   }
//   if (id === 4) {
//     return Orders_text.orderdetail.shipped;
//   }
//   if (id === 5) {
//     return Orders_text.orderdetail.unreachable;
//   }
//   if (id === 6) {
//     return Orders_text.orderdetail.out_for_delivery;
//   }
//   if (id === 7) {
//     return Orders_text.orderdetail.returned;
//   }
//   if (id === 8) {
//     return Orders_text.orderdetail.order_return_confirmed;
//   }
//   if (id === 9) {
//     return Orders_text.orderdetail.order_delivered;
//   }
//   if (id === 10) {
//     return Orders_text.orderdetail.orderdetail_delivery_confirmed;
//   }
//   if (id === 11) {
//     return Orders_text.orderdetail.return_initiated;
//   }
//   if (id === 12) {
//     return Orders_text.orderdetail.return_pickedup;
//   }
//   if (id === 13) {
//     return Orders_text.orderdetail.order_return_confirmed;
//   }
//   if (id === 14) {
//     return Orders_text.orderdetail.order_return_disputed;
//   }
//   if (id === 15) {
//     return Orders_text.orderdetail.cancel_order;
//   }
//   if (id === 16) {
//     return Orders_text.orderdetail.cancel_order;
//   }
//   if (id === 17) {
//     return Orders_text.orderdetail.ready_for_pickup;
//   }
//   if (id === 18) {
//     return Orders_text.orderdetail.order_completed;
//   }
//   if (id === 19) {
//     return Orders_text.orderdetail.order_confirmed;
//   }
//   if (id === 20) {
//     return Orders_text.orderdetail.arrived_at_customer_location;
//   }
//   if (id === 21) {
//     return Orders_text.orderdetail.cancel_by_admin;
//   }
// };

export const currentOrderStatus = (id) => {
  switch (id) {
    case 1:
      return Orders_text.orderdetail.incomplete;
      break;
    case 2:
      return Orders_text.orderdetail.order_placed;
      break;
    case 3:
      return Orders_text.orderdetail.order_in_progress;
      break;
    case 4:
      return Orders_text.orderdetail.shipped;
      break;
    case 5:
      return Orders_text.orderdetail.order_delivered;
      break;

    case 6:
      return Orders_text.orderdetail.cancelled_by_customer;
      break;
    case 7:
      return Orders_text.orderdetail.cancelled_by_admin;
      break;
    case 8:
      return Orders_text.orderdetail.order_completed;
      break;

    default:
      break;
  }
};

//

// Change shipment Status
export const ShipmentStatus = (id) => {
  if (id === 1) {
    return Orders_text.orderdetail.shipment_created;
  }
  if (id === 2) {
    return Orders_text.orderdetail.shipment_in_progress;
  }
  if (id === 3) {
    return Orders_text.orderdetail.ship;
  }
  if (id === 4) {
    return Orders_text.orderdetail.ready_for_pickup;
  }
  if (id === 5) {
    return Orders_text.orderdetail.out_for_delivery;
  }
  if (id === 6) {
    return Orders_text.orderdetail.undelivered_return;
  }
  if (id === 7) {
    return Orders_text.orderdetail.shipment_deliver;
  }
  if (id === 8) {
    return Orders_text.orderdetail.shipment_delivery_confirm;
  }
  if (id === 9) {
    return Orders_text.orderdetail.return_initiate;
  }
  if (id === 10) {
    return Orders_text.orderdetail.return_pickup;
  }
  if (id === 11) {
    return Orders_text.orderdetail.shipment_return_confirm;
  }
  if (id === 12) {
    return Orders_text.orderdetail.cancel_by_seller;
  }
  if (id === 13) {
    return Orders_text.orderdetail.cancel_by_customer;
  }
  if (id === 14) {
    return Orders_text.orderdetail.arrive_at_customer_location;
  }
  if (id === 15) {
    return Orders_text.orderdetail.cancel_by_admin;
  }
};
// Change shipment Status
export const currentShipmentStatus = (id) => {
  if (id === 1) {
    return Orders_text.orderdetail.shipment_created;
  }
  if (id === 2) {
    return Orders_text.orderdetail.shipment_in_progress;
  }
  if (id === 3) {
    return Orders_text.orderdetail.shipped;
  }
  if (id === 4) {
    return Orders_text.orderdetail.ready_for_pickup;
  }
  if (id === 5) {
    return Orders_text.orderdetail.out_for_delivery;
  }
  if (id === 6) {
    return Orders_text.orderdetail.undelivered_returned;
  }
  if (id === 7) {
    return Orders_text.orderdetail.shipment_delivered;
  }
  if (id === 8) {
    return Orders_text.orderdetail.shipment_delivery_confirmed;
  }
  if (id === 9) {
    return Orders_text.orderdetail.return_initiated;
  }
  if (id === 10) {
    return Orders_text.orderdetail.return_pickedup;
  }
  if (id === 11) {
    return Orders_text.orderdetail.shipment_return_confirmed;
  }
  if (id === 12) {
    return Orders_text.orderdetail.cancelled_by_seller;
  }
  if (id === 13) {
    return Orders_text.orderdetail.cancelled_by_customer;
  }
  if (id === 14) {
    return Orders_text.orderdetail.arrived_at_customer_location;
  }
  if (id === 15) {
    return Orders_text.orderdetail.cancelled_by_admin;
  }
};

// Options
export const options = [
  { value: 'All', label: Orders_text.orderdetail.all, id: 0 },
  {
    value: 'Confirmed',
    label: Orders_text.orderdetail.order_confirmed,
    id: 2,
  },
  {
    value: 'In progress',
    label: Orders_text.orderdetail.order_in_progress,
    id: 3,
  },
  { value: 'Shipped', label: Orders_text.orderdetail.shipped, id: 4 },
  {
    value: 'Delivered',
    label: Orders_text.orderdetail.order_delivered,
    id: 5,
  },

  {
    value: 'Cancel by customer',
    label: Orders_text.orderdetail.cancelled_by_you,
    id: 6,
  },
  {
    value: 'Cancel by admin',
    label: Orders_text.orderdetail.cancelled_by_admin,
    id: 7,
  },
];

export const filter_id = (name) => {
  const selected = options.filter((item) => {
    if (name === SlugChecker(`${item.value}`)) {
      return item;
    }
  });

  return selected[0].id;
};
export const filter_name = (name) => {
  const selected = options.filter((item) => {
    if (name === SlugChecker(`${item.value}`)) {
      return item;
    }
  });

  return SlugChecker(`${selected[0].value}`);
};
