export var Add_product_text = {
  addproduct: {
    information_listing: 'Information Listing',
    video_listing: 'Video Listing',
    //
    select_color: 'Select color',
    variant_form_open_alert:
      'Before adding a product you need to add your variant or close the variant form',
    donation_start_date: 'Start Date',
    donation_end_date: 'End Date',
    time_zone: 'Time zone',
    time_zone_alert: 'Select your time zone',
    listing_tax: 'Select Listing Taxes',
    physical_product: 'Physical Product',
    event_booking: 'Event Booking',
    digital_product: 'Digital Product',
    appointment_booking: 'Appointment Booking',
    crowdfunding: 'Crowdfunding',
    goal_price: 'Goal Price',
    alert_start_and_date_not_found: 'Start date and end date are required',
    image_size_alert:
      'Image size is too large. Please select an image less than or equal to 1 MB.',
    weight: 'Weight',
    dimension: 'Dimension',
    weight_alert: 'Add your product weight',
    dimension_alert: 'Add your product dimension',
    seller_protection_fee: 'Enroll in Seller Protection Policy',
    seller_protection_fee_info: `Secure your sales with a guaranteed compensation of up to €10 000, shielding your items from non-payment, loss or damage for a minimal percentage of 3.25 only.
`,

    listing_slug: 'Listing Slug',
    listing_meta_title: 'Meta Title',
    listing_meta_description: 'Meta Description',
    listing_meta_keyword: 'Meta Keyword',

    attribute_add_value: 'Use commas to add multiple values',
    alert_listing_title: 'Add Listing title',
    alert_listing_address: 'Add Listing address',
    alert_attribute_required: '{value} is required',
    alert_max_image_upload: ' Max. {value} photos per Listing',
    alert_address_not_found: 'Address Not found',
    type: 'Type',
    multi_write_value_info: 'Using a comma, you can enter several values.',
    upload_attachment: 'Upload Attachment',
    upload_file: 'Upload file',
    upload_json_file: 'Upload Json file',
    alert_price: 'Price is required',
    edit_listing_title: ' Edit Your Listing',
    add_image: 'Add Image',
    back_to_home: 'Back to home',
    back_to_account: 'Back to account',
    schedule_updated: 'Your schedules updated successfully',
    updated_product_successfully: 'Your listing updated successfully',
    vew_listing: 'View Listing',

    add_product: 'Add Listing',
    add_product_photo: 'Add Your Listing Photo',
    add_products: 'Add Listings',
    add_tag: 'Add Tag',
    adding: 'Adding',
    address: 'Address',
    alert_image_select_add_product: 'Select atleast 1 Listing image',
    alert_message_add_product_failed:
      'Couldnt add Listing at a moment. Try again later',
    alert_message_add_product_success: 'Listing added successfully',
    alert_message_app_permission_gallery:
      'App needs permission to access external storage',
    alert_message_app_permission_location:
      'App need permission to access location',
    alert_message_camera_permission:
      'App need camera permission to capture image',
    alert_message_choose_category: 'Choose Category',
    alert_message_choose_currency: 'Choose Currency',
    alert_message_invalid_price: 'Invalid Price',
    alert_message_max_quantity: 'Please enter Max Quantity at least one',
    alert_message_update_product_success: 'Listing updated successfully',
    alert_message_upload_file_failed: 'Cannot upload file at the moment',
    category: 'Category',
    clearvalue: 'Clear',
    continue_listing: 'Continue Listing',
    description: 'Description',
    header_title: 'Listing Details',
    imagefield: 'Image',
    max_quantity: 'Maximum Quantity',
    max_quantity_validation: 'Maximum Quantity Should be more than 0',
    min_price_validation: 'Price Should be greater than or equal to {value}',
    min_price_validation: 'Price Should be greater than or equal to {value}',
    min_price_validation_with_currency:
      'Price should be greater than or equal to %.2f {value}',
    offer_fieldvalidation: 'Offer Should be in the range 0% to 100%',
    offer_percent: 'Offer Percent',
    posted_success_message: 'Your listing posted successfully',
    price: 'Price',
    price_validation: 'Price should be more than 0.00',
    price_validation: 'Price should be more than 0.00',
    price_validation_with_currency: 'Price should be more than 0.00 {value}',
    product_title: 'Listing Title',
    promote_listing: 'Promote Your Listing',
    sell: 'Sell',
    shipping_charges: 'Shipping Charges',
    shipping_validation: 'Shipping charges Should not be less than 0',
    sub_category: 'Sub Category',
    tag_your_product: 'Tag your Listing (optional)',
    title: 'Title',
    update_product: 'Update Listing',
    addproduct_max_quantity: 'Max Quantity',
    addproduct_price_offer: 'Price Offer (in percentage)',
    addproduct_upload_image: 'Uploading image',
    addproduct_upload_listing: 'Upload listing',
    date_time: 'Schedules',
    variants: 'Variants',
    select: 'Select',
    add_variant: 'Add Variant',
    select_single_value: 'Select Single Value',
    select_multi_value: 'Select Multi Value',

    //Addedd
    stock: 'Stock',
  },

  // addvariant
  addvariant: {
    set_all_variant_values: 'Set All Variants values',
    stock: 'Stock',

    add_variant_image: 'Variant Image',
    variant_limit_text: 'Only {value} products in stock',
    no_variants_available_alert: 'No variants available',
    updated: 'Variant updated successfully',
    edit_variant: 'Edit Variant',

    done: 'Done',
    variant_type: 'Variant Type',
    select_variant_type: 'Select variant type',
    variant_type_value: 'Variant Type Value',
    select_variant_type_value: 'Select variant type value',
    add_variant: 'Add variant',
    title: 'Add variants',
    name: 'Name',
    description: 'Description',
    price: 'Price',
    offer: 'Offer',
    ticket_limit: 'Ticket Limit',
    submit: 'Submit',
    delete: 'Delete',
    delete_conformation: 'Are you sure you want to delete this',
    yes: 'Yes',
    no: 'No',
  },

  //add digitalproduct
  adddigitalproduct: {
    title: 'Title',
    description: 'Description',
    Version: 'Version',
    add_digital_product_btn_title: 'Select file',
    update_digital_product_btn_title: 'Update digital product',
    updated: 'Digital product updated successfully',
    alert_file_select: 'Select atleast 1 Digital Product',
    digital_product_title: 'Digital product',
    add_digital_product_title: 'Upload Digital product',
  },

  // addpricingrule
  addpricingrule: {
    title: 'Title',
    description: 'Description',
    select_variant: 'Select Variant',
    select_schedule: 'Select Schedule',
    price: 'Price',
    schedule: 'Schedule',
    purchase_options: 'Pricing Options',
    flexible: 'Flexible (Custom Quantity)',
    bulk: ' Bulk (All Tickets)',
    batch: 'Batch (Predefined Groups)',
    quantity: 'Quantity',
    min_quantity: 'Min Quantity',
    title_alert: 'Title is required',
    desc_alert: 'Description is required',
    list_price_alert: 'List price is required',
    pricing_rules: 'Pricing Options',
    pricing_rule: 'Pricing Option',
    edit_pricing_rule: 'Edit Pricing Option',
    add_pricing_rule: 'Add pricing option',
    purchase_options_info1:
      '1. Flexible (Custom Quantity): Choose any number of tickets you want.',
    purchase_options_info2:
      '2. Batch (Predefined Groups): Purchase tickets in predefined groups (e.g., 2, 4, 6).',
    purchase_options_info3:
      '3. Bulk (All Tickets): Buy all available tickets together.',
  },
};
