import axios from 'axios';
import { get_orders } from '../../store/feature/orderSlice';

export const pawa_pay_checkup = async ({ router, setIsLoading }) => {
  setIsLoading(true);
  axios
    .post('/api/web_payments/check_pawa_pay', {
      depositId: router.query.depositId,
      order_reference: router.query.order_reference,
    })
    .then((res) => {
      setIsLoading(false);
      router.push(
        `/checkout_success/${router.query.payment_method}/${router.query.order_reference}?status=success`
      );
    })
    .catch((err) => {
      setIsLoading(false);
    });
};
