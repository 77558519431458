import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearUserBaseNegotiateState,
  negotiateSelector,
  userBaseNegotiationsList,
} from '../../../../store/feature/negotiate/negotiateSlice';
import AllIcons, { user_icon } from '../../../Shared/Constant/Icons/AllIcons';
import {
  changeDateFormat,
  getThumbnailImage,
} from '../../../Shared/Constant/Constant';
import SellerNegotiationCards from './SellerNegotiationCards';
import { listingSelector } from '../../../../store/feature/listingSlice';
import NegotiateSkeleton from '../../../Shared/skeletonUI/NegotiateSkeleton';
import { Listing_text } from '../../../../constant/Translation/Listing';

const SellerNegotiateList = ({ dynamic_data }) => {
  const dispatch = useDispatch();
  const {
    seller_negotiations_list,
    isFetching,
    user_base_negotiations_list,
    isUserBaseListFetching,
  } = useSelector(negotiateSelector);
  const { listing_details } = useSelector(listingSelector);

  //
  const [selectedUser, setSelectedUser] = useState(null);

  //
  useEffect(() => {
    if (selectedUser && selectedUser) {
      dispatch(
        userBaseNegotiationsList({
          listing_id: listing_details?.id,
          params: {
            user_id: selectedUser?.id,
            account_id: listing_details?.account?.id,
          },
        })
      );
    }
  }, [selectedUser]);

  return (
    <div className="w-full h-full flex flex-col  ">
      <div className=" w-full sticky top-0">
        {/* Title */}
        <div className=" relative flex flex-col  px-10   py-4  borderer-b bg-gray-100   ">
          <p className="title-h1  text-center  ">
            {selectedUser
              ? `${selectedUser?.first_name} ${Listing_text.product.buyer_negotiations_list}`
              : `${Listing_text.product.all_negotiations_list}`}
          </p>

          {/* back button */}
          {selectedUser && (
            <button
              className=" absolute top-0 bottom-0 my-auto text-gray-600 hover:text-primary duration-300"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedUser(null);
                dispatch(clearUserBaseNegotiateState());
              }}
            >
              {AllIcons.back_button}
            </button>
          )}
        </div>
      </div>

      {/*  */}
      {!selectedUser && !user_base_negotiations_list && (
        <div className=" flex flex-col gap-4 p-4">
          {seller_negotiations_list?.map((item, index) => {
            return (
              <>
                <div
                  className="   w-full  min-h-20 flex   justify-between items-center p-4 shadow  rounded-md  cursor-pointer  "
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedUser(item?.user);
                  }}
                >
                  <div className="flex items-center justify-start gap-3 ">
                    {item?.user?.profile_pic ? (
                      <img
                        src={getThumbnailImage(item?.user?.profile_pic)}
                        alt={item?.user?.name}
                        className=" h-12 w-12 rounded-full overflow-hidden relative object-cover  object-left-top   "
                      />
                    ) : (
                      user_icon
                    )}
                    <div className="flex flex-col gap-0.5">
                      <p className="description-paragraph text-primary">
                        {item?.user?.first_name}{' '}
                        {Listing_text.product.buyer_made_offer}
                      </p>
                      <p className="description-caption">
                        {changeDateFormat(item?.created_at, 'hh:mm DD-MM-YYYY')}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}

      {/*  child level*/}
      {selectedUser &&
        !isUserBaseListFetching &&
        user_base_negotiations_list &&
        user_base_negotiations_list?.length > 0 && (
          <div className="flex flex-col gap-4 py-4">
            {user_base_negotiations_list?.map((list_item, index, array) => {
              const item_from_last = array[array.length - 1 - index];
              return (
                <SellerNegotiationCards
                  selectedItem={item_from_last}
                  selectedUser={selectedUser}
                  dynamic_data={dynamic_data}
                />
              );
            })}
          </div>
        )}

      {/*  Skeleton*/}

      {selectedUser && isUserBaseListFetching && <NegotiateSkeleton />}
    </div>
  );
};

export default SellerNegotiateList;
