/* eslint-disable @typescript-eslint/no-var-requires */
import axios from 'axios';
import { set } from 'react-hook-form';
import tradly from 'tradly';
import { Image_upload } from '../../../constant/functions';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import { Add_product_text } from '../../../constant/Translation/AddProduct_text';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { slug as SlugChecker } from 'github-slugger';
import { dateToEpochInSeconds } from '../../Shared/Constant/Constant';
import { replace_special_characters } from '../../../utils/ReplaceSpecialChar';

export const add_product_click = async (
  files,
  fullFile,
  title,
  order_type,
  slug,
  meta_title,
  meta_description,
  meta_keyword,
  description,
  price,
  goal_price,
  shippingCharge,
  offerPercent,
  quantity,
  coordinates,
  full_location,
  selectedCategory,
  attributeData,
  currency,
  setShowError,
  setError_message,
  dispatch,
  router,
  listing_configs,
  auth_key,
  accountId,
  setAddProductLoading,
  schedulesArray,
  variantsArray,
  attributes,
  digitalProducts,
  setDigitalProducts,
  startAt,
  endAt,
  weight_uom,
  weight_value,
  seller_protection_fee_enabled,
  tags,
  height_value,
  width_value,
  size_uom,
  length_value,
  showVariantForm,
  time_zone
) => {
  if (router?.query?.isEditor === 'true') {
    return;
  }

  setAddProductLoading(true);

  // Checking some conditions before api call
  if (files === null || !files?.length > 0) {
    setShowError(true);
    setError_message(
      Add_product_text.addproduct.alert_image_select_add_product
    );
    setAddProductLoading(false);
    return false;
  }
  if (title?.replace(/\s/g, '').length <= 0) {
    setShowError(true);
    setError_message(Add_product_text.addproduct.alert_listing_title);
    setAddProductLoading(false);
    return false;
  }

  if (price === '' && order_type !== 'donation') {
    setShowError(true);
    setError_message(Add_product_text.addproduct.alert_price);
    setAddProductLoading(false);
    return false;
  }
  if (
    price < parseInt(listing_configs?.listing_min_price) &&
    order_type !== 'donation'
  ) {
    setShowError(true);
    setError_message(
      dynamic_translation_set(
        Add_product_text.addproduct.min_price_validation,
        parseInt(listing_configs?.listing_min_price)
      )
    );
    setAddProductLoading(false);
    return false;
  }
  if (listing_configs.listing_enable_dimensions && order_type == 'listings') {
    if (
      height_value === null ||
      size_uom === null ||
      width_value === null ||
      length_value === null
    ) {
      setShowError(true);
      setError_message(Add_product_text.addproduct.dimension_alert);
      setAddProductLoading(false);
      return false;
    }
  }
  if (listing_configs.listing_enable_weight && order_type == 'listings') {
    if (weight_value === null || weight_uom === null) {
      setShowError(true);
      setError_message(Add_product_text.addproduct.weight_alert);
      setAddProductLoading(false);
      return false;
    }
  }
  if (listing_configs.listing_address_enabled) {
    if (coordinates === null) {
      setShowError(true);
      setError_message(Add_product_text.addproduct.alert_listing_address);
      setAddProductLoading(false);
      return false;
    }
  }
  if (listing_configs.enable_stock) {
    if (quantity === null || quantity === '') {
      setShowError(true);
      setError_message(Add_product_text.addproduct.alert_message_max_quantity);
      setAddProductLoading(false);
      return false;
    }
  }
  if (order_type === 'donation' && (startAt === null || endAt === null)) {
    setShowError(true);
    setError_message(
      Add_product_text.addproduct.alert_start_and_date_not_found
    );
    setAddProductLoading(false);
    return false;
  }
  if (order_type === 'donation' && (startAt !== null || endAt !== null)) {
    startAt = startAt ? dateToEpochInSeconds(startAt) : null;
    endAt = endAt ? dateToEpochInSeconds(endAt) : null;
  }

  if (selectedCategory === null) {
    setShowError(true);
    setError_message(Add_product_text.addproduct.alert_message_choose_category);
    setAddProductLoading(false);
    return false;
  }

  // Attribute checking
  if (attributes?.length > 0) {
    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i];
      if (!attribute.optional) {
        if (
          !attributeData?.filter((at_data) => at_data.id === attribute.id)
            ?.length > 0 ||
          attributeData === null
        ) {
          setShowError(true);
          setError_message(
            dynamic_translation_set(
              Add_product_text.addproduct.alert_attribute_required,
              attribute.name
            )
          );
          setAddProductLoading(false);
          return false;
          break;
        }
      }
    }
  }
  // Condition check for content

  if (order_type === 'digital' && !digitalProducts?.content?.length > 0) {
    setShowError(true);
    setError_message(Add_product_text.adddigitalproduct.alert_file_select);
    setAddProductLoading(false);
    return false;
  }

  //
  if (order_type === 'events' && time_zone === null) {
    setShowError(true);
    setError_message(Add_product_text.addproduct.time_zone_alert);
    setAddProductLoading(false);
    return false;
  }

  //
  if (showVariantForm) {
    setShowError(true);
    setError_message(Add_product_text.addproduct.variant_form_open_alert);
    setAddProductLoading(false);
    return false;
  }

  // Get Image url function:
  const file_uri = async (all_files, isPrivate) => {
    let all_files_uri = [];
    let upload_files = [];
    let upload_full_files = [];
    for (let i = 0; i < all_files.length; i++) {
      const element = all_files[i];

      // Set in state
      if (element.full_file === null) {
        all_files_uri.push(element.path);
      } else {
        let file_data = { name: element.name, type: element.type };
        if (isPrivate) {
          file_data.private = true;
        }
        upload_files.push(file_data);
        upload_full_files.push(element.full_file);
      }

      // Upload images checking
      if (all_files.length === i + 1 && upload_files.length > 0) {
        try {
          const response = await axios.post('/api/generateS3ImageURL', {
            data: {
              files: upload_files,
            },
          });

          const responseFiles = response.data.result;
          for (let index = 0; index < responseFiles.length; index++) {
            const path = responseFiles[index].signedUrl;
            const fileURI = responseFiles[index].fileUri;

            try {
              const res = await fetch(path, {
                method: 'PUT',
                headers: {
                  ContentType: upload_files[index].type,
                },
                body: upload_full_files[index],
              });

              if (res.ok) {
                all_files_uri.push(fileURI);
              }
            } catch (error) {
              setShowError(true);
              setError_message(error?.response?.data?.message);
              setAddProductLoading(false);
            }
          }
        } catch (error) {
          setShowError(true);
          setError_message(error?.response?.data?.message);
          setAddProductLoading(false);
        }
      }

      // return all images url
      if (all_files_uri.length == all_files.length) {
        return all_files_uri;
      }
    }
  };

  // GET IMAGE URL of Attributes:
  const upload_attributes_files = async (all_attributes) => {
    let all_updated_attributes = [];
    let upload_attributes_files = [];
    let upload_attributes_full_files = [];

    for (let i = 0; i < all_attributes.length; i++) {
      const element = all_attributes[i];

      // Set in state
      if (element.uploadFile) {
        upload_attributes_files.push({
          name: element.values[0].name,
          type: element.values[0].type,
          id: element.id,
        });
        upload_attributes_full_files.push(element.values[0]);
      } else {
        all_updated_attributes.push(element);
      }

      // Upload file checking
      if (
        all_attributes.length === i + 1 &&
        upload_attributes_files.length > 0
      ) {
        try {
          const response = await axios.post('/api/generateS3ImageURL', {
            data: {
              files: upload_attributes_files.map((file) => {
                return { name: file.name, type: file.type };
              }),
            },
          });

          const responseFiles = response.data.result;
          for (let index = 0; index < responseFiles.length; index++) {
            const path = responseFiles[index].signedUrl;
            const fileURI = responseFiles[index].fileUri;

            try {
              const res = await fetch(path, {
                method: 'PUT',
                headers: {
                  ContentType: upload_attributes_files[index].type,
                },
                body: upload_attributes_full_files[index],
              });

              if (res.ok) {
                all_updated_attributes.push({
                  values: [fileURI],
                  id: upload_attributes_files[index].id,
                });
              }
            } catch (error) {
              setShowError(true);
              setError_message(error?.response?.data?.message);
              setAddProductLoading(false);
            }
          }
        } catch (error) {
          setShowError(true);
          setError_message(error?.response?.data?.message);
          setAddProductLoading(false);
        }
      }

      // return all images url
      if (all_updated_attributes.length == all_attributes.length) {
        return all_updated_attributes;
      }
    }
  };

  try {
    const images_response = await file_uri(fullFile);
    if (images_response?.length > 0) {
      if (attributeData !== null && attributeData?.length !== 0) {
        const check = attributeData.find((attr) => attr.uploadFile);
        if (check === undefined) {
          const listingData = {
            list_price: price,
            account_id: accountId,
            currency_id: currency,
            attributes: attributeData,
            title: title,
            offer_percent: offerPercent,
            images: images_response,
            category_id: [selectedCategory],
            type: 'listings',
            order_type: order_type,
          };

          if (Number(goal_price) > 0) {
            listingData['goal_price'] = Number(goal_price);
          }
          if (order_type === 'events' && time_zone) {
            listingData['time_zone'] = time_zone;
          }
          if (order_type === 'donation' && startAt) {
            listingData['start_at'] = startAt;
          }
          if (order_type === 'donation' && endAt) {
            listingData['end_at'] = endAt;
          }
          if (listing_configs.listing_address_enabled) {
            listingData['coordinates'] = coordinates;
          }
          if (full_location != null || full_location != undefined) {
            listingData['location'] = full_location;
          }
          if (listing_configs.enable_stock) {
            listingData['stock'] = quantity;
          }
          if (listing_configs.show_shipping_charges) {
            listingData['shipping_charges'] = shippingCharge;
          }
          if (
            listing_configs.listing_enable_weight &&
            order_type == 'listings'
          ) {
            listingData['weight_uom'] = weight_uom;
            listingData['weight_value'] = weight_value;
          }
          if (
            listing_configs.listing_enable_dimensions &&
            order_type == 'listings'
          ) {
            listingData['size_uom'] = size_uom;
            listingData['width'] = Number(width_value);
            listingData['height'] = Number(height_value);
            listingData['length'] = Number(length_value);
          }
          if (
            listing_configs.listing_seller_protection_fee_enabled &&
            order_type == 'listings'
          ) {
            listingData['seller_protection_fee_enabled'] =
              seller_protection_fee_enabled;
          }
          if (!description?.replace(/\s/g, '').length <= 0) {
            listingData['description'] = description;
          }
          if (!tags?.replace(/\s/g, '').length <= 0) {
            listingData['tags'] = tags.split(',');
          }
          if (!meta_title?.replace(/\s/g, '').length <= 0) {
            listingData['meta_title'] = meta_title;
          }
          if (!meta_keyword?.replace(/\s/g, '').length <= 0) {
            listingData['meta_keyword'] = meta_keyword;
          }
          if (!meta_description?.replace(/\s/g, '').length <= 0) {
            listingData['meta_description'] = meta_description;
          }
          if (!slug?.replace(/\s/g, '').length <= 0) {
            listingData['slug'] = SlugChecker(slug);
          } else {
            listingData['slug'] = SlugChecker(
              replace_special_characters(title)
            );
          }

          // post listing function

          axios
            .post('/api/l/post_listing', {
              data: { listing: listingData },
            })
            .then(async (res) => {
              let changeRoute = true;
              const listingId = res.data.listing.id;

              // Schedule upload
              if (schedulesArray !== null && schedulesArray.length > 0) {
                await axios
                  .post('/api/schedules/create_schedule', {
                    id: res.data.listing.id,
                    data: { schedules: schedulesArray },
                  })
                  .then((res) => {
                    changeRoute = true;
                  })
                  .catch((error) => {
                    changeRoute = false;
                    setShowError(true);
                    setError_message(error.response?.data?.message);
                    setAddProductLoading(false);
                  });
              }
              // digital product update
              if (digitalProducts && order_type === 'digital') {
                await upload_digital_products(
                  listingId,
                  digitalProducts,
                  setShowError,
                  setError_message,
                  setAddProductLoading,
                  file_uri,
                  accountId
                );
              }

              // Varinat upload
              if (variantsArray !== null && variantsArray.length > 0) {
                await upload_variants(
                  { title, price },
                  listingId,
                  variantsArray,

                  setShowError,
                  setError_message,
                  setAddProductLoading,
                  file_uri,
                  accountId
                );
              }

              //  after finish
              push_data_layer({
                event: 'add_listing',
                data: {
                  title: title,
                },
              });
              setAddProductLoading(false);
              router.push('/a/my-account');
            })
            .catch((error) => {
              setShowError(true);
              setError_message(error?.response?.data.message);
              setAddProductLoading(false);
            });
        } else {
          try {
            const updated_attributes = await upload_attributes_files(
              attributeData
            );

            const listingData = {
              list_price: price,
              account_id: accountId,
              currency_id: currency,
              attributes: updated_attributes,
              title: title,
              offer_percent: offerPercent,
              images: images_response,
              category_id: [selectedCategory],
              type: 'listings',
              order_type: order_type,
            };

            if (Number(goal_price) > 0) {
              listingData['goal_price'] = Number(goal_price);
            }
            if (order_type === 'events' && time_zone) {
              listingData['time_zone'] = time_zone;
            }
            if (order_type === 'donation' && startAt) {
              listingData['start_at'] = startAt;
            }
            if (order_type === 'donation' && endAt) {
              listingData['end_at'] = endAt;
            }
            if (listing_configs.listing_address_enabled) {
              listingData['coordinates'] = coordinates;
            }
            if (full_location != null || full_location != undefined) {
              listingData['location'] = full_location;
            }
            if (listing_configs.enable_stock) {
              listingData['stock'] = quantity;
            }
            if (listing_configs.show_shipping_charges) {
              listingData['shipping_charges'] = shippingCharge;
            }
            if (
              listing_configs.listing_enable_weight &&
              order_type == 'listings'
            ) {
              listingData['weight_uom'] = weight_uom;
              listingData['weight_value'] = weight_value;
            }
            if (
              listing_configs.listing_enable_dimensions &&
              order_type == 'listings'
            ) {
              listingData['size_uom'] = size_uom;
              listingData['width'] = Number(width_value);
              listingData['height'] = Number(height_value);
              listingData['length'] = Number(length_value);
            }
            if (
              listing_configs.listing_seller_protection_fee_enabled &&
              order_type == 'listings'
            ) {
              listingData['seller_protection_fee_enabled'] =
                seller_protection_fee_enabled;
            }
            if (!description?.replace(/\s/g, '').length <= 0) {
              listingData['description'] = description;
            }
            if (!tags?.replace(/\s/g, '').length <= 0) {
              listingData['tags'] = tags.split(',');
            }
            if (!meta_title?.replace(/\s/g, '').length <= 0) {
              listingData['meta_title'] = meta_title;
            }
            if (!meta_keyword?.replace(/\s/g, '').length <= 0) {
              listingData['meta_keyword'] = meta_keyword;
            }
            if (!meta_description?.replace(/\s/g, '').length <= 0) {
              listingData['meta_description'] = meta_description;
            }
            if (!slug?.replace(/\s/g, '').length <= 0) {
              listingData['slug'] = SlugChecker(slug);
            } else {
              listingData['slug'] = SlugChecker(
                replace_special_characters(title)
              );
            }

            // post listing function

            axios
              .post('/api/l/post_listing', {
                data: { listing: listingData },
              })
              .then(async (res) => {
                let changeRoute = true;
                const listingId = res.data.listing.id;
                if (schedulesArray !== null && schedulesArray.length > 0) {
                  await axios
                    .post('/api/schedules/create_schedule', {
                      id: res.data.listing.id,
                      data: {
                        schedules: schedulesArray,
                      },
                    })
                    .then((res) => {
                      // setAddProductLoading(false);
                      // router.push('/stores/my-account');
                      changeRoute = true;
                    })
                    .catch((error) => {
                      changeRoute = false;
                      setShowError(true);
                      setError_message(error?.response?.data?.message);
                      setAddProductLoading(false);
                    });
                }
                // digital product update
                if (digitalProducts && order_type === 'digital') {
                  await upload_digital_products(
                    listingId,
                    digitalProducts,
                    setShowError,
                    setError_message,
                    setAddProductLoading,
                    file_uri,
                    accountId
                  );
                }
                if (variantsArray !== null && variantsArray.length > 0) {
                  await upload_variants(
                    { title, price },
                    listingId,
                    variantsArray,

                    setShowError,
                    setError_message,
                    setAddProductLoading,
                    file_uri,
                    accountId
                  );
                }
                push_data_layer({
                  event: 'add_listing',
                  data: {
                    title: title,
                  },
                });
                setAddProductLoading(false);
                router.push('/a/my-account');
              })
              .catch((error) => {
                setShowError(true);
                setError_message(error?.response?.data.message);
                setAddProductLoading(false);
              });
          } catch (error) {
            setShowError(true);
            setError_message(error.response.data.message);
            setAddProductLoading(false);
          }
        }
      } else {
        const listingData = {
          list_price: price,
          account_id: accountId,
          currency_id: currency,
          title: title,
          offer_percent: offerPercent,
          images: images_response,
          category_id: [selectedCategory],
          type: 'listings',
          order_type: order_type,
        };

        if (Number(goal_price) > 0) {
          listingData['goal_price'] = Number(goal_price);
        }
        if (order_type === 'events' && time_zone) {
          listingData['time_zone'] = time_zone;
        }
        if (order_type === 'donation' && startAt) {
          listingData['start_at'] = startAt;
        }
        if (order_type === 'donation' && endAt) {
          listingData['end_at'] = endAt;
        }
        if (listing_configs.listing_address_enabled) {
          listingData['coordinates'] = coordinates;
        }
        if (full_location != null || full_location != undefined) {
          listingData['location'] = full_location;
        }
        if (listing_configs.enable_stock) {
          listingData['stock'] = quantity;
        }
        if (listing_configs.show_shipping_charges) {
          listingData['shipping_charges'] = shippingCharge;
        }
        if (listing_configs.listing_enable_weight && order_type == 'listings') {
          listingData['weight_uom'] = weight_uom;
          listingData['weight_value'] = weight_value;
        }
        if (
          listing_configs.listing_enable_dimensions &&
          order_type == 'listings'
        ) {
          listingData['size_uom'] = size_uom;
          listingData['width'] = Number(width_value);
          listingData['height'] = Number(height_value);
          listingData['length'] = Number(length_value);
        }
        if (
          listing_configs.listing_seller_protection_fee_enabled &&
          order_type == 'listings'
        ) {
          listingData['seller_protection_fee_enabled'] =
            seller_protection_fee_enabled;
        }
        if (!description?.replace(/\s/g, '').length <= 0) {
          listingData['description'] = description;
        }
        if (!tags?.replace(/\s/g, '').length <= 0) {
          listingData['tags'] = tags.split(',');
        }
        if (!meta_title?.replace(/\s/g, '').length <= 0) {
          listingData['meta_title'] = meta_title;
        }
        if (!meta_keyword?.replace(/\s/g, '').length <= 0) {
          listingData['meta_keyword'] = meta_keyword;
        }
        if (!meta_description?.replace(/\s/g, '').length <= 0) {
          listingData['meta_description'] = meta_description;
        }
        if (!slug?.replace(/\s/g, '').length <= 0) {
          listingData['slug'] = SlugChecker(slug);
        } else {
          listingData['slug'] = SlugChecker(replace_special_characters(title));
        }

        // post listing function

        axios
          .post('/api/l/post_listing', {
            data: { listing: listingData },
          })
          .then(async (res) => {
            let changeRoute = true;
            const listingId = res.data.listing.id;
            if (schedulesArray !== null && schedulesArray.length > 0) {
              await axios
                .post('/api/schedules/create_schedule', {
                  id: res.data.listing.id,
                  data: { schedules: schedulesArray },
                })
                .then(async (res) => {
                  // setAddProductLoading(false);
                  // router.push('/stores/my-account');
                  changeRoute = true;
                })
                .catch((error) => {
                  changeRoute = false;
                  setShowError(true);
                  setError_message(error.response.data.message);
                  setAddProductLoading(false);
                });
            }
            // digital product update
            if (digitalProducts && order_type === 'digital') {
              await upload_digital_products(
                listingId,
                digitalProducts,
                setShowError,
                setError_message,
                setAddProductLoading,
                file_uri,
                accountId
              );
            }
            if (variantsArray !== null && variantsArray.length > 0) {
              await upload_variants(
                { title, price },
                listingId,
                variantsArray,
                setShowError,
                setError_message,
                setAddProductLoading,
                file_uri,
                accountId
              );
            }

            //
            push_data_layer({
              event: 'add_listing',
              data: {
                title: title,
              },
            });
            setAddProductLoading(false);
            router.push('/a/my-account');
          })
          .catch((error) => {
            setShowError(true);
            setError_message(error?.response?.data?.message);
            setAddProductLoading(false);
          });
      }
    }
  } catch (error) {
    console.log(error);
    setShowError(true);
    setError_message(error.response?.data?.message);
    setAddProductLoading(false);
  }
};

// Upload variant
const upload_variants = async (
  listing_data,
  listingId,
  variantsArray,
  setShowError,
  setError_message,
  setAddProductLoading,
  file_uri,
  accountId
) => {
  let isLoopFinish = 0;
  for (let i = 0; i < variantsArray.length; i++) {
    const element = variantsArray[i];

    let images_uri = element?.images
      ? await Image_upload(
          [{ path: null, full_file: element?.images }],
          setShowError,
          setError_message
        )
      : undefined;

    try {
      let variant_data;
      if (images_uri?.length > 0) {
        variant_data = {
          active: true,
          title: element.title || listing_data.title,
          description: element.description || ' ',
          list_price: element.list_price || listing_data.price,
          offer_percent: element.offer_percent || 0,
          stock: element.stock || 0,
          images: images_uri,
          variant_values: element.variant_values,
        };
      } else {
        variant_data = {
          active: true,
          title: element.title || listing_data.title,
          description: element.description || ' ',
          list_price: element.list_price || listing_data.price,
          offer_percent: element.offer_percent || 0,
          stock: element.stock || 0,
          variant_values: element.variant_values,
        };
      }

      const res = await axios.post('/api/variant/add_variant', {
        listingId,
        data: {
          variant: { ...variant_data },
        },
      });
      if (res) {
        if (element?.digitalProducts && order_type === 'digital') {
          // Upload Digital content from variant lvel
          upload_digital_products(
            listingId,
            element?.digitalProducts,
            setShowError,
            setError_message,
            setAddProductLoading,
            file_uri,
            accountId,
            true, //isVarinat,
            res.data.variant.id //variant id
          ).then((res) => {
            if (res) {
              isLoopFinish = isLoopFinish + 1;
              if (isLoopFinish === variantsArray.length + 1) {
                return true;
              }
            } else {
              // This condition need to check latter(When there will be any error upload any digital thing from variant )
              isLoopFinish = isLoopFinish + 1;
              if (isLoopFinish === variantsArray.length + 1) {
                return true;
              }
            }
          });
        } else {
          isLoopFinish = isLoopFinish + 1;
          if (isLoopFinish === variantsArray.length + 1) {
            return true;
          }
        }
      }
    } catch (error) {
      setShowError(true);
      setError_message(error.response.data.message);
      setAddProductLoading(false);
      return false;
    }
  }
};

// Upload digital products
const upload_digital_products = async (
  listingId,
  digitalProducts,
  setShowError,
  setError_message,
  setAddProductLoading,
  file_uri,
  accountId,
  isVariant,
  variantID
) => {
  // destructure
  const { account_id, title, description, version_name, content, active } =
    digitalProducts || {};

  //
  let digital_file_data = {
    account_id: account_id ?? accountId,
    active: active,
  };

  if (!title?.replace(/\s/g, '').length <= 0) {
    digital_file_data['title'] = title;
  }
  if (!description?.replace(/\s/g, '').length <= 0) {
    digital_file_data['description'] = description;
  }

  if (!version_name?.replace(/\s/g, '').length <= 0) {
    digital_file_data['version_name'] = version_name;
  }
  if (isVariant) {
    digital_file_data.variant_id = variantID;
  }

  const uploaded_files = await file_uri(
    content.map((item) => {
      return {
        full_file: item.full_file ?? null,
        path: item.file_url ?? '',
        name: item.file_name,
        type: item.mime_type,
      };
    }),
    true
  );

  if (uploaded_files?.length > 0) {
    digital_file_data.content = content.map((item, index) => {
      return {
        size: item.size,
        mime_type: item.mime_type,
        file_name: item.file_name,
        file_url: uploaded_files[index],
      };
    });

    try {
      const res = await axios.post('/api/digital_contents', {
        listing_id: listingId,
        data: {
          digital_content: { ...digital_file_data },
        },
      });
      if (res) {
        return true;
      }
    } catch (error) {
      setShowError(true);
      setError_message(error.response.data.message);
      setAddProductLoading(false);
      return false;
    }
  }
};
