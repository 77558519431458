import React from 'react';
import { Shipping_text } from '../../../constant/Translation/Shipping_text';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const CheckoutLatestButton = ({
  editor_versions_list,
  current_editor_version,
  clickCheckOut,
  isCheckoutFetching,
  data,
  styles,
}) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        className="flex items-center   "
        style={get_dynamic_styles(styles?.box_styles)}
      >
        <button
          className="     rounded-button  flex items-center justify-center  gap-2 "
          type="button"
          target={data?.button_target === '_blank' ? '_blank' : '_self'}
          style={get_dynamic_styles(styles?.button_styles)}
          onClick={() => {
            clickCheckOut({
              is_push_checkout_success_page:
                data?.is_push_checkout_success_page,
            });
          }}
        >
          {data?.icon && data?.is_icon_in_right != true && (
            <img
              className="w-auto object-cover  "
              style={get_dynamic_styles(styles?.icon_styles)}
              src={data?.icon}
              alt="image"
            />
          )}

          <p
            className="text-lg font-semibold text-gray-700"
            style={get_dynamic_styles(styles?.text_styles)}
          >
            {Shipping_text.shipping.checkout}
          </p>

          {data?.icon && data?.is_icon_in_right == true && (
            <img
              className="w-auto object-cover  "
              style={get_dynamic_styles(styles?.icon_styles)}
              src={data?.icon}
              alt="image"
            />
          )}

          {isCheckoutFetching && (
            <span>
              <svg
                className="animate-spin    h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default CheckoutLatestButton;
