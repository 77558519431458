import React from 'react';
import Link from 'next/link';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useRouter } from 'next/router';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const NavItem = ({ nav, styles }) => {
  const router = useRouter();
  const isActive = router?.pathname === nav?.href;

  console.log(isActive);
  return (
    <Link href={nav?.href ?? '#'}>
      <a
        className="relative"
        style={{
          display: styles?.nav_item?.display,
          ...get_dynamic_styles(styles?.nav_item),
        }}
      >
        {nav?.image && (
          <img
            style={get_dynamic_styles(styles?.nav_item_image)}
            src={nav?.image}
            alt=""
          />
        )}
        {nav?.title && (
          <p style={get_dynamic_styles(styles?.nav_item_title)}>
            {get_data_by_current_language({
              data_list: nav?.translated_data,
              key_name: 'title',
              default_data: nav?.title,
            })}
          </p>
        )}

        {/* active style */}
        {isActive && <div style={get_dynamic_styles(styles?.active_style)} />}
      </a>
    </Link>
  );
};

export default NavItem;
