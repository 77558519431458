import React, { useEffect, useState } from 'react';
import SearchBox from '../SearchBox/SearchBox';
import StoreButton from './StoreButton';
import Link from 'next/link';
import HeaderCategories from './HeaderCategories';
import OutsideClickHandler from 'react-outside-click-handler';
import Drawer from './Drawer';
import WishListButton from '../WishListButton/WishListButton';
import HeaderProfile2 from '../HeaderProfileBox/HeaderProfile2';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Add_listing_button from '../Shared/Buttons/Add_listing_button';
import Cart_button from '../HeaderButton/Cart_button';
import Notifications3 from '../NotificationsButton/Notifications3';
import { getCookie } from 'cookies-next';
import Language_switch from '../HeaderButton/Language_switch_button';
import { Menubar_text } from '../../constant/Translation/Heading';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { myStore } from '../../store/feature/storeSlice';
import {
  AccountCategories,
  categories,
} from '../../store/feature/categorySlice';
import { getNotifications } from '../../store/feature/notificationSlice';
import CommonMenus from './CommonMenus';
import { useScreenSize } from '../../hooks/useScreenSize';
import HeaderMenuLink from '../StaticBlocks/HeaderMenuLink';
import DynamicHeaderProfile from '../HeaderProfileBox/DynamicHeaderProfile';
import SignInButton from '../HeaderButton/SignInButton';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import BuilderHeaderCategories from './DynamicHeaderSecvtions/BuilderHeaderCategories';
import Heading2Block from '../StaticBlocks/Heading2Block';
import DividerBlock from '../StaticBlocks/DividerBlock';
import CategoriesDropDown from './DynamicHeaderSecvtions/CategoriesDropDown';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { HeaderDefaultColumnStyles } from '../../constant/default_styles/HeaderDefaultStyles';
import DynamicSearchBox from '../SearchBox/DynamicSearchBox';
import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../utils/ResponsiveDesign';
import GlobalSwitch from '../HeaderButton/GlobalSwitch';
import CurrencySwitch from '../HeaderButton/CurrencySwitch';
import { latest_editor_version } from '../../constant/Version';
import WalletButton from '../HeaderButton/WalletButton';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import HamburgerButton from '../HeaderButton/HamburgerButton';
import { common_blocks_cases } from '../../themes/blank/BlockDecide/CommonBlockCase';
import AnimatedTextBlock from '../StaticBlocks/AnimatedTextBlock';
import SocialMedia from '../Footer/sections/SocialMedia';
import CustomCta from '../StaticBlocks/CustomCta';
import ImageBlock from '../StaticBlocks/ImageBlock';

const DynamicHeader = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const DynamicHeaderStyle = TYPE_CONSTANT.STYLES_CONFIGS?.dynamic_header;

  const { width } = useScreenSize();

  const [logo, setLogo] = useState(localStorage.getItem('logo'));
  const [login, setLogin] = useState(localStorage.getItem('login') ?? false);
  let userDetails = JSON.parse(localStorage.getItem('user_details'));

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const drawerOpen = () => {
    const drawer = document.getElementById('sideDrawer');
    drawer.classList.remove('-translate-x-full');
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  // Effect
  useEffect(() => {
    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`)) {
      dispatch(
        myStore({
          prams: {
            page: 1,
            type: 'accounts',
            user_id: userDetails?.id,
          },
        })
      );
    }
    dispatch(
      categories({
        prams: { parent: 0, type: 'listings' },
      })
    );
    dispatch(
      AccountCategories({
        prams: { parent: 0, type: 'accounts' },
      })
    );
  }, []);

  //Notifications Effect
  useEffect(() => {
    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`)) {
      dispatch(
        getNotifications({
          page: 1,
        })
      );
    }
  }, [router]);

  // check_unauthorized_guest
  const check_unauthorized_guest = (isShowUnauthorizedGuest) => {
    const isLoggedIN = getCookie(`${TYPE_CONSTANT.DOMAIN}_login`);

    return isShowUnauthorizedGuest ? true : isLoggedIN ? true : false;
  };

  // check_authorized_peop
  const check_authorized_people = (isShowAuthorizedPeople) => {
    const isLoggedIN = getCookie(`${TYPE_CONSTANT.DOMAIN}_login`);

    return isShowAuthorizedPeople ? true : isLoggedIN ? false : true;
  };

  // component decide
  const component_decide = ({
    block,
    block_code,
    isStatic,
    data,
    styles,
    isEnabled = true,
    items,
  }) => {
    const {
      isShowInMobile,
      isShowInWeb,
      isShowUnauthorizedGuest,
      isShowAuthorizedPeople,
    } = data || {};
    const section = {
      block,
      block_code,
      isStatic,
      data,
      styles,
      isEnabled,
      items,
    };

    // logo_styles
    const logo_styles = responsive_design_styles({
      width,
      parent_group: styles,
      child_group_name: 'logo_styles',
    });

    switch (block) {
      case 'logo':
        return (
          isEnabled &&
          ((isShowInMobile !== false && width < 768) ||
            (isShowInWeb !== false && width > 768)) && (
            <Link href="/" passHref={true}>
              <a className="  flex items-center   relative cursor-pointer  ">
                <img
                  src={logo}
                  style={{
                    // height: styles?.height ?? '50px',
                    // width: styles?.width ?? '',
                    maxHeight: logo_styles?.max_height ?? '44px',
                    width: logo_styles?.width,
                    objectFit: logo_styles?.object_fit ?? 'contain',
                  }}
                  alt="logo"
                />
              </a>
            </Link>
          )
        );

      case 'category':
        return (
          isEnabled &&
          ((isShowInMobile !== false && width < 768) ||
            (isShowInWeb !== false && width > 768)) && (
            <div className="flex-grow">
              <BuilderHeaderCategories />
            </div>
          )
        );
      case 'category_dropdown':
        return (
          isEnabled &&
          ((isShowInMobile !== false && width < 768) ||
            (isShowInWeb !== false && width > 768)) && (
            <div className="flex-grow">
              <CategoriesDropDown />
            </div>
          )
        );
      case 'search':
        return (
          isEnabled &&
          ((isShowInMobile !== false && width < 768) ||
            (isShowInWeb !== false && width > 768)) && (
            <DynamicSearchBox styles={styles} />
          )
        );
      case 'handburger':
        return (
          isEnabled &&
          ((isShowInMobile !== false && width < 768) ||
            (isShowInWeb !== false && width > 768)) && (
            <button
              style={get_dynamic_styles(styles?.button_styles)}
              className="outline-none"
              onClick={drawerOpen}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 ltr:mr-[13px] rtl:ml-[13px] cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          )
        );
      case 'handburger_button':
        return (
          isEnabled &&
          ((isShowInMobile !== false && width < 768) ||
            (isShowInWeb !== false && width > 768)) && (
            <HamburgerButton
              drawerOpen={drawerOpen}
              data={data}
              styles={styles}
            />
          )
        );
      case 'custom':
        return (
          isEnabled &&
          ((isShowInMobile !== false && width < 768) ||
            (isShowInWeb !== false && width > 768)) && (
            <HeaderMenuLink data={data} />
          )
        );
      case 'add_listing_btn':
        return (
          isEnabled &&
          TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <div className={isShowInMobile ? 'block' : ' hidden md:block  '}>
              <Add_listing_button
                title={Menubar_text.more.header_sell_btn_title}
                btn_styles={'py-[9px]'}
              />
            </div>
          )
        );
      case 'notification_btn':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <div
              className={isShowInMobile ? 'block' : ' hidden md:block  '}
              id="header_notification_button"
            >
              <Notifications3 />
            </div>
          )
        );
      case 'cart_btn':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <div className={isShowInMobile ? 'block' : ' hidden md:block  '}>
              <Cart_button />
            </div>
          )
        );
      case 'wallet_btn':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <div className={isShowInMobile ? 'block' : ' hidden md:block  '}>
              <WalletButton />
            </div>
          )
        );
      case 'wishlist_btn':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <div
              className={isShowInMobile ? 'block' : ' hidden md:block  '}
              id="header_wish_list_button"
            >
              <WishListButton />
            </div>
          )
        );
      case 'store_btn':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) &&
          TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 && (
            <div
              className={isShowInMobile ? 'block' : ' hidden md:block  '}
              id="header_store_button"
            >
              <StoreButton />
            </div>
          )
        );
      case 'profile_menubar':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <div
              className={isShowInMobile ? 'block' : ' hidden md:block  '}
              id="header_nav_items"
            >
              <DynamicHeaderProfile />
            </div>
          )
        );
      case 'language_switch_btn':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <div
              className={isShowInMobile ? 'block' : ' hidden md:block  '}
              id="header_language_switch_button"
            >
              <Language_switch />
            </div>
          )
        );
      case 'currency_switch_btn':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <div
              className={isShowInMobile ? 'block' : ' hidden md:block  '}
              id="header_currency_switch_button"
            >
              <CurrencySwitch />
            </div>
          )
        );
      case 'global_switch_btn':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <div className={isShowInMobile ? 'block' : ' hidden md:block  '}>
              <GlobalSwitch />
            </div>
          )
        );
      case 'sign_in_signup':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <div className={isShowInMobile ? 'block' : ' hidden md:block  '}>
              <SignInButton />
            </div>
          )
        );
      case 'divider_block':
        return <DividerBlock styles={styles} />;

      case 'heading2_block':
        return <Heading2Block data={data} styles={styles} />;
      case 'animated_paragraph':
        return <AnimatedTextBlock data={data} styles={styles} />;
      case 'social_links':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <SocialMedia data={data} styles={styles} />
          )
        );
      case 'cta':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <CustomCta data={data} styles={styles} />
          )
        );
      case 'image_block':
        return data?.enabled && <ImageBlock data={data} styles={styles} />;
      default:
        return common_blocks_cases({ section });
    }
  };

  // const header main styles
  const header_main_styles = responsive_design_styles({
    parent_group: DynamicHeaderStyle?.styles,
    child_group_name: 'main_styles',
    width,
  });

  return (
    <>
      <div
        className={header_main_styles?.isSticky && 'sticky z-50 top-0'}
        id="template-main-header"
        style={get_dynamic_styles(header_main_styles)}
      >
        {isDrawerOpen ? (
          <div className="  top-0 z-40 fixed  h-full w-full bg-black   opacity-30" />
        ) : (
          ''
        )}

        <div
          className={` relative   mx-auto`}
          style={get_dynamic_styles(
            responsive_design_styles({
              parent_group: DynamicHeaderStyle?.styles,
              child_group_name: 'box_styles',
              width,
            })
          )}
          id="header_section"
        >
          {/* Rows */}

          {DynamicHeaderStyle?.schema?.map((row) => {
            const {
              columns,
              current_editor_version = '',
              ...column_styles
            } = row || {};
            const row_styles = responsive_row_styles({
              parent_group: row,
              width,
            });
            return current_editor_version === latest_editor_version
              ? row_styles?.display !== 'none' && (
                  <div
                    style={{
                      display: row_styles?.display,
                      gridTemplateColumns: dynamic_text_convert({
                        main_text: row_styles?.columns_box_percentage,
                        check_by: '-1%',
                        value: '',
                      }),
                      ...get_dynamic_styles(row_styles),
                    }}
                  >
                    {/* Columns */}
                    {columns.map((column, index) => {
                      const current_column_style = responsive_column_styles({
                        parent_group: column_styles,
                        index,
                        width,
                      });

                      return (
                        <div
                          style={{
                            display: current_column_style?.display
                              ? current_column_style?.display
                              : 'flex',
                            ...get_dynamic_styles_with_default_value(
                              current_column_style,
                              HeaderDefaultColumnStyles
                            ),
                          }}
                        >
                          {column?.map((item) => {
                            return (
                              component_decide({ ...item }) &&
                              component_decide({ ...item })
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                )
              : ((width < 768 && row_styles?.isShowInMobile !== false) ||
                  (width > 768 && row_styles?.isShowInWeb !== false)) && (
                  <div
                    className={`flex items-center `}
                    style={{
                      justifyContent: row_styles?.justify_content,
                      gap: row_styles?.gap_between_columns,
                    }}
                  >
                    {/* Columns */}
                    {columns.map((column, index) => {
                      const current_column_style = responsive_column_styles({
                        parent_group: column_styles,
                        index,
                        width,
                      });

                      return (
                        <div
                          className={[
                            `flex  `,
                            current_column_style?.isOverFollowHidden &&
                              'flex-wrap  overflow-hidden justify-start',
                            current_column_style?.isFlexNone
                              ? 'flex-none'
                              : 'flex-grow',
                          ].join(' ')}
                          style={get_dynamic_styles_with_default_value(
                            current_column_style,
                            HeaderDefaultColumnStyles
                          )}
                        >
                          {column?.map((item) => {
                            return (
                              component_decide({ ...item }) &&
                              component_decide({ ...item })
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
          })}
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            closeDrawer();
          }}
        >
          {isDrawerOpen && (
            <button
              onClick={closeDrawer}
              className={[
                ' absolute  top-0  z-50 bg-gray-50 p-2 text-primary ',
                isDrawerOpen
                  ? 'ltr:left-[245px]  rtl:right-[245px]  transition  ease-in-out  duration-700    '
                  : 'ltr:left-0  rtl:right-0 transform  transition ltr:-translate-x-full  rtl:translate-x-full    ease-in-out  duration-700',
              ].join(' ')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
          <div
            id="sideDrawer"
            className={
              isDrawerOpen
                ? 'z-50 bg-gray-50  w-[245px] fixed inset-y-0 ltr:left-0  rtl:right-0 transform  transition  ease-in-out  opacity-100 duration-500 overflow-y-scroll'
                : '  order-9 bg-gray-50  w-[245px] fixed inset-y-0 ltr:left-0 rtl:right-0  transform  transition ltr:-translate-x-full  rtl:translate-x-full  ease-in-out  duration-500 opacity-0'
            }
          >
            <div className=" ">
              <Drawer />
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default React.memo(DynamicHeader);
