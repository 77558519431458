import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import {
  clearListings,
  getAllListings,
  listingLike,
  listingSelector,
} from '../../../store/feature/listingSlice';
import ReactPaginate from 'react-paginate';
import { Listing_text } from '../../../constant/Translation/Listing';
import DynamicListingCard from '../../Shared/Cards/DynamicListingCard';

import { check_login } from '../../../constant/check_auth';
import InfiniteScroll from 'react-infinite-scroll-component';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import AccountsMapViewSkeleton from '../../Shared/skeletonUI/AccountsMapViewSkeleton';
import { Account_text } from '../../../constant/Translation/Account';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../../constant/default_styles/HomeDefaultStyles';
import DynamicAccounts from '../../AllAccounts/Accounts/DynamicAccounts';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import AccountListViewSkeleton from '../../Shared/skeletonUI/AccountsListViewSkeleton';
import {
  dynamicPageSectionOptimisticUpdate,
  dynamicPageSelector,
  getDynamicPageSection,
  setError,
} from '../../../store/feature/Pages/DynamicPage/dynamicPageSlice';
import { removeKeysFromObject } from '../../../utils/keyRemoveFromObject';
import { EXTRA_QUERY_KEYS } from '../../../data/ExtraQueryKeys';

const StoresListViewForDynamicPage = ({
  section_details,
  all_accounts_data,
  styles,
  AllAccountFilter,
  setAllAccountFilter,
}) => {
  const account_card_styles = TYPE_CONSTANT.STYLES_CONFIGS?.account_card ?? {};

  //
  const {
    order_number,
    block,
    block_data_key,
    fetch_by,
    data,
    isStatic,
    extra_data,
  } = section_details || {};

  const [pageCount, setPageCount] = useState(0);
  const router = useRouter();
  const dispatch = useDispatch();

  const { dynamic_page_sections } = useSelector(dynamicPageSelector);

  //follow button handler
  const follow = (id, isFollow) => {
    if (check_login(router)) {
      followDataOptimisticUpdate({ isFollow, id });

      axios
        .post('/api/a/follow_account', { id, isFollow })
        .then((res) => {
          if (res.data) {
            //
          }
        })
        .catch((error) => {
          followDataOptimisticUpdate({ isFollow: !isFollow, id });
          dispatch(setError([{ msg: error?.response?.data?.message }]));
        });
    }
  };
  // follow data optimistic  update
  const followDataOptimisticUpdate = ({ isFollow, id }) => {
    dispatch(
      dynamicPageSectionOptimisticUpdate(
        dynamic_page_sections.map((section) => {
          if (section.order_number == section_details?.order_number) {
            return {
              ...section,
              data: [
                {
                  ...section.data[0],
                  accounts: [
                    ...section.data[0].accounts?.map((acc) => {
                      if (acc.id !== id) {
                        return acc;
                      } else {
                        return { ...acc, following: !isFollow };
                      }
                    }),
                  ],
                },
              ],
            };
          } else {
            return section;
          }
        })
      )
    );
  };

  const moreAccounts = (current_page) => {
    dispatch(
      getDynamicPageSection({
        sections_data: [
          {
            ...section_details,
            fetch_by: {
              ...fetch_by,
              page: current_page,
            },
          },
        ],
      })
    );
  };

  //  Count and set total page
  useEffect(() => {
    const totalpage = Math.ceil(
      all_accounts_data?.total_records / fetch_by?.per_page
    );
    if (Number(all_accounts_data?.total_records) > fetch_by?.per_page) {
      setPageCount(totalpage);
    }
  }, [all_accounts_data?.total_records]);

  // fetch listings base on category
  useEffect(() => {
    const filter_query_params = removeKeysFromObject({
      object_params: router.query,
      key_to_remove: ['draft', 'iframe', 'page_slug', ...EXTRA_QUERY_KEYS],
    });

    //
    if (Object.keys(filter_query_params)?.length > 0) {
      dispatch(
        getDynamicPageSection({
          sections_data: [
            {
              ...section_details,
              extra_data: {
                ...section_details.extra_data,
                fetching_type: 'fetching_by_effect',
              },
              fetch_by: {
                ...fetch_by,
                ...AllAccountFilter,
                ...filter_query_params,
              },
            },
          ],
        })
      );
    }
  }, [AllAccountFilter]);

  return (
    <>
      {all_accounts_data !== null ? (
        <div
          className="w-full   "
          style={get_dynamic_styles(styles?.main_styles)}
        >
          <div
            className="w-full   "
            style={get_dynamic_styles_with_default_value(
              styles?.box_styles,
              HomeCardBoxDefaultStyles
            )}
          >
            {all_accounts_data?.accounts?.length > 0 ? (
              <div>
                {/* Map view Infinite scroll */}
                {extra_data?.pagination_type === 'scroll' && (
                  <InfiniteScroll
                    dataLength={all_accounts_data?.accounts?.length}
                    next={() =>
                      moreAccounts(Number(all_accounts_data?.page) + 1)
                    }
                    hasMore={
                      all_accounts_data?.accounts.length ===
                      all_accounts_data?.total_records
                        ? false
                        : true
                    }
                    loader={
                      account_card_styles?.card_type?.type !== 'list_card' ? (
                        <AccountsMapViewSkeleton
                          per_page={fetch_by?.per_page}
                        />
                      ) : (
                        <AccountListViewSkeleton
                          per_page={fetch_by?.per_page}
                        />
                      )
                    }
                  >
                    <DynamicAccounts
                      accounts={all_accounts_data?.accounts}
                      account_follow={follow}
                    />
                  </InfiniteScroll>
                )}

                {/* pagination type  */}
                {extra_data?.pagination_type !== 'scroll' && (
                  <>
                    <DynamicAccounts
                      accounts={all_accounts_data?.accounts}
                      account_follow={follow}
                    />

                    <div className="mt-12   flex justify-center pb-12">
                      {all_accounts_data !== null && (
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={
                            <svg
                              className="h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          }
                          onPageChange={(data) =>
                            moreAccounts(Number(data.selected) + 1)
                          }
                          pageRangeDisplayed={2}
                          pageCount={pageCount}
                          previousLabel={
                            <svg
                              className="h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          }
                          renderOnZeroPageCount={null}
                          containerClassName=""
                          className="relative   inline-flex flex-wrap justify-center rounded-button shadow-sm -space-x-px "
                          pageClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center text-sm font-medium"
                          pageLinkClassName="px-4 py-2 border"
                          previousClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          nextClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          breakLinkClassName="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                          activeLinkClassName="  bg-primary  border-primary text-white relative inline-flex items-center px-4 py-2 border text-md font-semibold"
                          disabledLinkClassName=""
                          prevPageRel="2"
                          forcePage={all_accounts_data?.page - 1}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className=" w-full h-[200px] mt-5 flex justify-center items-start">
                <div
                  className="w-full    md:w-5/6 bg-yellow-500    text-white px-4 py-3 rounded-card  relative grid grid-cols-[5%,80%]"
                  role="alert"
                >
                  <div className="flex items-center justify-center w-6 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                  </div>
                  <div className="ml-5">
                    <span className="   md:ml-2">
                      {Account_text.storelist.no_stores_available}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div style={get_dynamic_styles(styles?.main_styles)}>
          <div
            className="w-full   "
            style={get_dynamic_styles_with_default_value(
              styles?.box_styles,
              HomeCardBoxDefaultStyles
            )}
          >
            {account_card_styles?.card_type?.type !== 'list_card' ? (
              <AccountsMapViewSkeleton per_page={fetch_by?.per_page} />
            ) : (
              <AccountListViewSkeleton per_page={fetch_by?.per_page} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default StoresListViewForDynamicPage;
