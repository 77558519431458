/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';
import { setCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

// My store
export const myStore = createAsyncThunk(
  'store/myStore',

  async ({ prams }, thunkAPI) => {
    try {
      const response = await axios.get('/api/a/my_account', { params: prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Address search
export const getAddressSearch = createAsyncThunk(
  'store/getAddressSearch',

  async ({ searchKey, session_token, type }, thunkAPI) => {
    try {
      const response = await axios.get(`/api/address/search_address`, {
        params: { session_token, type, search_key: searchKey },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Attribute address search
export const getAttributeAddressSearch = createAsyncThunk(
  'store/getAttributeAddressSearch',

  async ({ searchKey, authKey }, thunkAPI) => {
    try {
      const response = await axios.get(`/api/address/search_address`, {
        params: { search_key: searchKey },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Categories
export const categories = createAsyncThunk(
  'store/categories',

  async ({ prams, authKey }, thunkAPI) => {
    try {
      const response = await axios.get('/api/categories', { params: prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Listing categories
export const listingCategories = createAsyncThunk(
  'store/listingCategories',

  async ({ prams, authKey }, thunkAPI) => {
    try {
      const response = await axios.get('/api/categories', { params: prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Account Attribute
export const accountAttribute = createAsyncThunk(
  'store/accountAttribute',

  async ({ prams, authKey }, thunkAPI) => {
    try {
      const response = await axios.get('/api/attributes', { params: prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// all attributes
export const allAttributes = createAsyncThunk(
  'store/allAttributes',
  async ({ prams }, thunkAPI) => {
    try {
      const response = await axios.get('/api/attributes', { params: prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Create account
export const postStore = createAsyncThunk(
  'store/postStore',

  async ({ id, prams, authKey }, thunkAPI) => {
    try {
      const response = await axios.post('/api/a/my_account', { id, prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// My account Listings
export const myAccountListings = createAsyncThunk(
  'store/myAccountListings',

  async ({ prams }, thunkAPI) => {
    try {
      const response = await axios.get('/api/l', { params: prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Account Listings
export const accountListings = createAsyncThunk(
  'store/accountListings',

  async ({ prams }, thunkAPI) => {
    try {
      const response = await axios.get('/api/l', { params: prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// My account Listing details
export const myAccountListingDetails = createAsyncThunk(
  'store/myAccountListingDetails',

  async ({ slug, id }, thunkAPI) => {
    try {
      const response = await axios.get(`/api/l/${slug}`, { params: { id } });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// FollowingAccountsListings
export const socialFeedListings = createAsyncThunk(
  'store/socialFeedListings',

  async ({ prams, authKey }, thunkAPI) => {
    try {
      const response = await axios.get('/api/a/social_feed', { params: prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Currencies
export const currencies = createAsyncThunk(
  'store/currencies',

  async ({}, thunkAPI) => {
    try {
      const response = await axios.get('/api/currencies');
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Get All accounts
export const get_all_accounts = createAsyncThunk(
  'store/get_all_accounts',

  async ({ bodyParam, authKey }, thunkAPI) => {
    try {
      const response = await axios.get('/api/a', { params: bodyParam });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Get All blocked accounts
export const get_all_blocked_accounts = createAsyncThunk(
  'store/get_all_blocked_accounts',

  async ({ bodyParam }, thunkAPI) => {
    try {
      const response = await axios.get('/api/a/block', { params: bodyParam });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Get all following store
export const get_all_following_accounts = createAsyncThunk(
  'store/get_all_following_accounts',

  async ({ bodyParam }, thunkAPI) => {
    try {
      const response = await axios.get('/api/a/follow_account', {
        params: bodyParam,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Get all following store
export const follow_account = createAsyncThunk(
  'store/follow_account',

  async ({ id, isFollow }, thunkAPI) => {
    try {
      const response = await axios.post('/api/a/follow_account', {
        id,
        isFollow,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Account Full details
export const account_full_details = createAsyncThunk(
  'store/account_full_details',

  async ({ slug, id }, thunkAPI) => {
    try {
      const response = await axios.get(`/api/a/${slug}`, { params: { id } });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Shipping Methods
export const tenantShippingMethods = createAsyncThunk(
  'cart/tenantShippingMethods',
  async ({}, thunkAPI) => {
    try {
      const response = await axios.get('/api/shipping_method');
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Store Reviews
export const getStoreReviews = createAsyncThunk(
  'listing/getStoreReviews',
  async ({ params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/review/get_reviews', {
        params: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Pickup address
export const getPickupAddress = createAsyncThunk(
  'cart/getPickupAddress',
  async ({ bodyParam }, thunkAPI) => {
    try {
      const response = await axios.get('/api/address/get_address', {
        params: bodyParam,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const storeSlice = createSlice({
  name: 'store',
  initialState: {
    isFetching: false,
    isAllAccountsFetching: false,
    addressFetching: false,
    attribute_addressFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    my_stores: null,
    account_categories: null,
    listing_categories: null,
    search_addresses: null,
    attribute_search_addresses: null,
    attributes: null,
    all_attributes: null,
    my_store_listings: null,
    my_store_listings_page: '',
    my_store_listings_total_records: '',
    account_listings: null,
    account_listings_page: '',
    account_listings_total_records: '',
    social_feed_listings: null,
    social_feed_listings_page: '',
    social_feed_listings_total_records: '',
    listing_configs: null,
    currencies: null,
    my_account_listing_details: null,
    all_accounts: [],
    all_accounts_total_records: '',
    all_accounts_page: '',
    all_blocked_accounts: null,
    all_blocked_accounts_total_records: '',
    all_blocked_accounts_page: '',
    all_following_accounts: null,
    all_following_accounts_total_records: '',
    all_following_accounts_page: '',
    my_account_details: null,
    tenant_shipping_methods: null,
    store_reviews: null,
    my_store_review: null,
    review_page: '',
    review_total_records: '',
    pickup_addresses: null,
  },
  reducers: {
    clearStoreState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      return state;
    },
    setListingConfig: (state, { payload }) => {
      state.listing_configs = payload?.listing_configs;
      return state;
    },
    clearAccountDetails: (state) => {
      state.my_account_details = null;
    },
    clearAccountListingDetails: (state) => {
      state.my_account_listing_details = null;
    },
    clearAttributes: (state) => {
      state.attributes = null;
    },
  },
  extraReducers: {
    [myStore.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.my_stores = payload?.accounts;
        if (payload?.accounts?.length > 0) {
          setCookie(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`, true);
          setCookie(
            `${TYPE_CONSTANT.DOMAIN}_storeAccountID`,
            payload?.accounts[0]?.id
          );
          setCookie(
            `${TYPE_CONSTANT.DOMAIN}_storeAccountCategoryID`,
            payload?.accounts[0]?.category_id?.[0]
          );
        }
      }
    },
    [myStore.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [myStore.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [getAddressSearch.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.addressFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.addressFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.search_addresses =
          TYPE_CONSTANT.ADDRESS_CONFIGS.map_type == 'google_map'
            ? payload?.places
            : payload?.addresses;
      }
    },
    [getAddressSearch.pending]: (state) => {
      state.addressFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getAddressSearch.rejected]: (state, { payload }) => {
      state.addressFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [getAttributeAddressSearch.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.attribute_addressFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.attribute_addressFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.attribute_search_addresses = payload?.addresses;
      }
    },
    [getAttributeAddressSearch.pending]: (state) => {
      state.attribute_addressFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getAttributeAddressSearch.rejected]: (state, { payload }) => {
      state.attribute_addressFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [categories.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.account_categories = payload?.categories;
      }
    },
    [categories.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [categories.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [listingCategories.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.listing_categories = payload?.categories;
      }
    },
    [listingCategories.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [listingCategories.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [myAccountListingDetails.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.my_account_listing_details = payload?.listing;
      }
    },
    [myAccountListingDetails.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [myAccountListingDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [accountAttribute.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.attributes = payload?.attributes;
      }
    },
    [accountAttribute.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [accountAttribute.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [myAccountListings.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.my_store_listings = payload?.listings;
        state.my_store_listings_page = payload?.page;
        state.my_store_listings_total_records = payload?.total_records;
      }
    },
    [myAccountListings.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [myAccountListings.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [accountListings.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.account_listings = payload?.listings;
        state.account_listings_page = payload?.page;
        state.account_listings_total_records = payload?.total_records;
      }
    },
    [accountListings.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [accountListings.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [socialFeedListings.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.social_feed_listings = payload?.listings;
        state.social_feed_listings_page = payload?.page;
        state.social_feed_listings_total_records = payload?.total_records;
      }
    },
    [socialFeedListings.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [socialFeedListings.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [currencies.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.currencies = payload?.currencies;
      }
    },
    [currencies.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [currencies.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [get_all_accounts.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isAllAccountsFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isAllAccountsFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.all_accounts = payload?.accounts;
        state.all_accounts_page = payload?.page;
        state.all_accounts_total_records = payload?.total_records;
      }
    },
    [get_all_accounts.pending]: (state) => {
      state.isAllAccountsFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [get_all_accounts.rejected]: (state, { payload }) => {
      state.isAllAccountsFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    // Blocked Accounts
    [get_all_blocked_accounts.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isAllAccountsFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isAllAccountsFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.all_blocked_accounts = payload?.accounts;
        state.all_blocked_accounts_total_records = payload?.page;
        state.all_blocked_accounts_page = payload?.total_records;
      }
    },
    [get_all_blocked_accounts.pending]: (state) => {
      state.isAllAccountsFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [get_all_blocked_accounts.rejected]: (state, { payload }) => {
      state.isAllAccountsFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    // follow_account accounts
    [follow_account.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
      }
    },
    [follow_account.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [follow_account.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    // following accounts
    [get_all_following_accounts.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isAllAccountsFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isAllAccountsFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.all_following_accounts = payload?.accounts;
        state.all_following_accounts_total_records = payload?.page;
        state.all_following_accounts_page = payload?.total_records;
      }
    },
    [get_all_following_accounts.pending]: (state) => {
      state.isAllAccountsFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [get_all_following_accounts.rejected]: (state, { payload }) => {
      state.isAllAccountsFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    //
    [account_full_details.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.my_account_details = payload?.account;
      }
    },
    [account_full_details.pending]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [account_full_details.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [tenantShippingMethods.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.tenant_shipping_methods = payload.shipping_methods;
      }
    },
    [tenantShippingMethods.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [tenantShippingMethods.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [getStoreReviews.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.store_reviews = payload?.reviews;
        state.my_store_review = payload?.my_review;
        state.review_page = payload?.page;
        state.review_total_records = payload?.total_records;
      }
    },
    [getStoreReviews.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getStoreReviews.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [getPickupAddress.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.pickup_addresses = payload?.addresses;
      }
    },
    [getPickupAddress.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getPickupAddress.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    [allAttributes.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.all_attributes = payload?.attributes;
      }
    },
    [allAttributes.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [allAttributes.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const {
  clearStoreState,
  setListingConfig,
  clearAccountDetails,
  clearAccountListingDetails,
  clearAttributes,
} = storeSlice.actions;
export const storeSelector = (state) => state.store;
