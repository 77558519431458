/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import { addToCart, cartList } from '../../../store/feature/cartSlice';
import { authSelector } from '../../../store/feature/authSlice';
import { Listing_text } from '../../../constant/Translation/Listing';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import SidePan from '../../Shared/Modal/SidePan';
import { listingDetails } from '../../../store/feature/listingSlice';
import {
  buyerNegotiationsList,
  negotiateSelector,
} from '../../../store/feature/negotiate/negotiateSlice';
import TabPopupUI from '../../Shared/PopUp/UI/TabPopupUI';
import BuyerNegotiationList from '../Negotiate/BuyerNegotiatePart/BuyerNegotiationList';
import SellerNegotiatePart from '../Negotiate/SellerNegotiatePart/SellerNegotiatePart';
import Cookies from 'js-cookie';
import SellerNegotiationCards from '../Negotiate/SellerNegotiatePart/SellerNegotiationCards';
const NegotiateButton = ({
  listing_details,
  selectedVariant,
  setError_message,
  setShowError,
}) => {
  const { login } = useSelector(authSelector);
  const router = useRouter();
  const dispatch = useDispatch();

  //selector
  const { buyer_negotiations_list, seller_negotiations_list } =
    useSelector(negotiateSelector);

  //
  const [buyerNegotiateSidePan, setBuyerNegotiateSidePan] = useState(false);

  //
  useEffect(() => {
    if (listing_details && login) {
      dispatch(
        buyerNegotiationsList({
          listing_id: listing_details?.id,
        })
      );
    }
  }, [listing_details, login]);
  // add to cart
  const add_to_Cart = (_isBuyNow) => {
    if (
      TYPE_CONSTANT.LISTINGS_CONFIGS?.enable_stock &&
      listing_details?.stock == 0
    ) {
      setShowError(true);
      setError_message(Listing_text.product.out_of_stock);
      return false;
    }
    if (_isBuyNow) {
      const cartData = {
        cart: {
          listing_id: listing_details?.id,
          quantity: 1,
        },
      };
      if (selectedVariant !== null) {
        cartData.cart['variant_id'] = selectedVariant;
      }
      dispatch(addToCart({ data: cartData })).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'buynow',
            data: {
              name: listing_details?.title,
              slug: listing_details?.slug,
              price: listing_details?.offer_price.amount,
              stock: listing_details?.stock,
              category: {
                id: listing_details?.categories?.[0]?.id,
                name: listing_details?.categories[0]?.name,
              },
              image: listing_details?.images,
              url: window.location.href,
            },
          });
          dispatch(cartList({}));
          dispatch(
            buyerNegotiationsList({
              listing_id: listing_details?.id,
            })
          );
          router.push('/checkout');
        } else {
          if (res.payload.code === 480) {
            setShowError(true);
            setError_message(Listing_text.product.multi_seller_error);
          } else {
            setShowError(true);
            setError_message(res.payload.message);
          }
        }
      });
    } else {
      const cartData = {
        cart: {
          listing_id: listing_details?.id,
          quantity: 1,
        },
      };
      if (selectedVariant !== null) {
        cartData.cart['variant_id'] = selectedVariant;
      }
      dispatch(addToCart({ data: cartData })).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'add_cart',
            data: {
              name: listing_details?.title,
              slug: listing_details?.slug,
              price: listing_details?.offer_price.amount,
              stock: listing_details?.stock,
              category: {
                id: listing_details?.categories[0]?.id,
                name: listing_details?.categories[0]?.name,
              },
              image: listing_details?.images,
              url: window.location.href,
            },
          });
          dispatch(cartList({}));
          dispatch(
            listingDetails({
              ...router?.query,
            })
          );
          setBuyerNegotiateSidePan(false);
        } else {
          if (res.payload.code === 480) {
            setShowError(true);
            setError_message(Listing_text.product.multi_seller_error);
          } else {
            setShowError(true);
            setError_message(res.payload.message);
          }
        }
      });
    }
  };

  //handleSidepanOpen
  const handleSidepanOpen = (e) => {
    e.stopPropagation();
    setBuyerNegotiateSidePan(true);
  };

  return (
    <>
      <div className=" w-full grid grid-cols-2 gap-4">
        <button
          className="     h-[50px] bg-primary rounded-button flex justify-center items-center gap-3 text-xl text-white font-semibold  "
          id="book_now"
          onClick={(e) => {
            login
              ? handleSidepanOpen(e)
              : router.push(`/sign-in?to=${router.asPath}`);
          }}
        >
          {Listing_text.product.negotiate}
        </button>
        <button
          className="   h-[50px]    flex justify-center items-center  btn-primary-fill "
          id="add_to_cart"
          // onClick={add_to_Cart}
          onClick={() => {
            login
              ? listing_details?.variants.length > 0
                ? selectedVariant === null
                  ? (setShowError(true),
                    setError_message(Listing_text.product.variant_validation))
                  : add_to_Cart(true)
                : add_to_Cart(true)
              : router.push(`/sign-in?to=${router.asPath}`);
          }}
          type="button"
        >
          <span className=" text-base sm:text-xl  font-semibold  ">
            {Listing_text.product.buy_now}
          </span>
        </button>
      </div>
      {/* Buyer Sidepan   step */}
      <SidePan
        id={`negotiate-1st-step-sidepan-buyer`}
        sidePanOpen={buyerNegotiateSidePan}
        setSidePanOpen={setBuyerNegotiateSidePan}
        modal_section_styles={
          'fixed  ltr:right-0 rtl:left-0 bottom-0 lg:top-0 lg:my-auto   z-[60]  block  rounded-t-[40px]   lg:rounded-card transform bg-white rounded shadow-lg overflow-auto  max-w-full md:max-w-xl w-full  max-h-[80VH] md:max-h-full  lg:scrollbar-thin   lg:scrollbar-track-transparent  lg:scrollbar-thumb-transparent '
        }
        modal_inside_section_styles="w-full h-full"
        modal_width={'max-w-xl w-full'}
      >
        <TabPopupUI
          title={Listing_text.product.negotiate}
          isShowTab={false}
          tab_list={[{ title: 'All', icon: '' }]}
          tab_panels={[
            <BuyerNegotiationList
              add_to_Cart={add_to_Cart}
              sidePanOpen={buyerNegotiateSidePan}
            />,
          ]}
        ></TabPopupUI>
      </SidePan>
    </>
  );
};
export default NegotiateButton;
