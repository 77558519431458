import axios from 'axios';
import { useRouter } from 'next/router';
import React, { memo } from 'react';
import Link from 'next/link';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const AboutUs = ({ data, styles }) => {
  return (
    <div
      className="w-full    flex  items-center justify-center"
      style={get_dynamic_styles(styles?.main_styles)}
    >
      <div
        className=" flex flex-col   "
        style={get_dynamic_styles(styles?.box_styles)}
      >
        {data?.title && (
          <h2 style={get_dynamic_styles(styles?.title)}>
            {get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'title',
              default_data: data?.title,
            })}
          </h2>
        )}
        {data?.description && (
          <p style={get_dynamic_styles(styles?.desc)}>
            {get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'description',
              default_data: data?.description,
            })}
          </p>
        )}
      </div>
    </div>
  );
};

export default memo(AboutUs);
