import { clearAttributes, postStore } from '../../../store/feature/storeSlice';
import axios from 'axios';
import { Add_store_text } from '../../../constant/Translation/AddStore_text';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import { slug as SlugChecker } from 'github-slugger';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

// Helper functions to improve readability and reusability
const validateStoreData = (
  imagePath,
  name,
  coordinates,
  preferenceShippingMethod,
  accounts_configs,
  address_state,
  attributes,
  attributeData,
  setShowError,
  setError_message,
  setEditStoreLoading
) => {
  const validations = [
    {
      condition: imagePath === null || imagePath?.path == undefined,
      message: Add_store_text.addstore.add_your_store_photo,
    },
    {
      condition: name?.replace(/\s/g, '').length <= 0,
      message: Add_store_text.addstore.alert_store_title,
    },
    {
      condition:
        accounts_configs.account_address_enabled && coordinates === null,
      message: Add_store_text.addstore.alert_address_not_found,
    },
    {
      condition:
        accounts_configs?.enable_shipping_methods_preference &&
        (!preferenceShippingMethod || preferenceShippingMethod.length === 0),
      message: Add_store_text.addstore.alert_shipping_method_not_found,
    },
    {
      condition:
        shouldValidatePickupAddress(
          preferenceShippingMethod,
          accounts_configs
        ) && address_state === null,
      message: Add_store_text.addstore.alert_pickup_address_not_found,
    },
  ];

  for (const validation of validations) {
    if (validation.condition) {
      setShowError(true);
      setError_message(validation.message);
      setEditStoreLoading(false);
      return false;
    }
  }

  // Validate required attributes
  if (attributes?.length > 0) {
    for (const attribute of attributes) {
      if (!attribute.optional) {
        const hasAttribute = attributeData?.some(
          (at_data) => at_data.id === attribute.id
        );
        if (!hasAttribute) {
          setShowError(true);
          setError_message(
            dynamic_translation_set(
              Add_store_text.addstore.alert_attribute_required,
              attribute.name
            )
          );
          setEditStoreLoading(false);
          return false;
        }
      }
    }
  }

  return true;
};

const createStoreData = (
  name,
  imagePath,
  coordinates,
  full_location,
  category,
  description,
  slug,
  preferenceShippingMethod,
  attributeData,
  accounts_configs,
  shippingCharge
) => {
  const storeData = {
    name,
    web_address: '',
    type: 'accounts',
    ...(imagePath && { images: [imagePath.path || imagePath] }),
    ...(accounts_configs.account_address_enabled && { coordinates }),
    ...(full_location && { location: full_location }),
    ...(category && { category_id: [category] }),
    ...(attributeData?.length && { attributes: attributeData }),
    ...(accounts_configs?.accounts_show_shipping_charges && {
      shipping_charges: shippingCharge > 0 ? { flat: shippingCharge } : {},
    }),
    ...(preferenceShippingMethod?.length && {
      shipping_methods: preferenceShippingMethod.map((item) => item.value),
    }),
    ...(description?.trim() && { description }),
    slug: slug?.trim() ? SlugChecker(slug) : SlugChecker(name),
  };

  return storeData;
};

const handleAddressUpdate = async (
  address_state,
  pickup_address_id,
  name,
  router,
  setEditStoreLoading,
  dispatch
) => {
  if (!address_state) return;

  try {
    await axios.post('/api/address/save_address', {
      id: pickup_address_id || '',
      addressData: {
        address: {
          ...Object.fromEntries(
            Object.entries(address_state).filter(([_, value]) => value !== '')
          ),
          type: 'pickup',
          current_address: true,
        },
      },
    });

    push_data_layer({
      event: 'edit_account',
      data: { name },
    });
    dispatch(clearAttributes());
    router.push('/a/my-account');
  } catch (error) {
    console.error('Address update failed:', error);
  } finally {
    setEditStoreLoading(false);
  }
};

const handleAttributeFileUpload = async (attributeData) => {
  // Find all attributes that have files to upload
  const fileAttributes = attributeData.filter((attr) => attr?.uploadFile);
  if (!fileAttributes.length) return attributeData;

  try {
    // Process each file attribute
    const processedAttributes = await Promise.all(
      fileAttributes.map(async (fileAttribute) => {
        const uploadResponse = await axios.post('/api/generateS3ImageURL', {
          data: {
            files: [
              {
                name: fileAttribute.values[0].name,
                type: fileAttribute.values[0].type,
              },
            ],
          },
        });

        if (uploadResponse.data.error) {
          throw new Error(uploadResponse.data.error.message);
        }

        const fileURL = uploadResponse.data.result[0];
        await fetch(fileURL.signedUrl, {
          method: 'put',
          headers: { ContentType: fileAttribute.values[0].type },
          body: fileAttribute.values[0],
        });

        // Return the processed attribute with new file path
        return {
          values: [fileURL.fileUri],
          id: fileAttribute.id,
        };
      })
    );

    // Combine non-file attributes with processed file attributes
    const nonFileAttributes = attributeData.filter((attr) => !attr?.uploadFile);

    return [...nonFileAttributes, ...processedAttributes];
  } catch (error) {
    throw new Error(
      `Attribute file upload failed: ${
        error?.response?.data?.error?.message || error.message
      }`
    );
  }
};

// Add this helper function with the other helper functions
const shouldValidatePickupAddress = (
  preferenceShippingMethod,
  accounts_configs
) => {
  if (!preferenceShippingMethod) return false;

  return (
    // Check if pickup method is selected
    preferenceShippingMethod.some((method) => method.type === 'pickup') ||
    // Check if delivery method requires pickup address
    (preferenceShippingMethod.some((method) => method.type === 'delivery') &&
      accounts_configs?.get_pickup_address_for_delivery === true) ||
    // Check if pickup address is always required
    TYPE_CONSTANT?.ADDRESS_CONFIGS?.get_account_pickup_address_always
  );
};

// Main function updated to handle attribute files
export const edit_store_click = async (
  files,
  imagePath,
  name,
  slug,
  description,
  coordinates,
  full_location,
  preferenceShippingMethod,
  category,
  attributeData,
  setShowError,
  setError_message,
  auth_key,
  dispatch,
  accountId,
  setEditStoreLoading,
  router,
  accounts_configs,
  address_state,
  pickup_address_id,
  attributes,
  shippingCharge
) => {
  setEditStoreLoading(true);

  // Validate input data
  if (
    !validateStoreData(
      imagePath,
      name,
      coordinates,
      preferenceShippingMethod,
      accounts_configs,
      address_state,
      attributes,
      attributeData,
      setShowError,
      setError_message,
      setEditStoreLoading
    )
  ) {
    return;
  }

  try {
    let finalImagePath = imagePath;
    let finalAttributeData = attributeData;

    // Handle main store image upload if new file is provided
    if (files) {
      const uploadResponse = await axios.post('/api/generateS3ImageURL', {
        data: { files: [{ name: files.name, type: files.type }] },
      });

      if (uploadResponse.data.error) {
        throw new Error(uploadResponse.data.error.message);
      }

      const fileURL = uploadResponse.data.result[0];
      await fetch(fileURL.signedUrl, {
        method: 'put',
        headers: { ContentType: files.type },
        body: files,
      });

      finalImagePath = fileURL.fileUri;
    }

    // Handle attribute file uploads if any
    if (attributeData?.length) {
      finalAttributeData = await handleAttributeFileUpload(attributeData);
    }

    // Create store data object with updated attribute data
    const storeData = createStoreData(
      name,
      finalImagePath,
      coordinates,
      full_location,
      category,
      description,
      slug,
      preferenceShippingMethod,
      finalAttributeData,
      accounts_configs,
      shippingCharge
    );

    // Update store
    const storeResponse = await dispatch(
      postStore({
        id: accountId,
        prams: { account: storeData },
        authKey: auth_key,
      })
    );

    if (storeResponse.payload.code) {
      throw new Error(storeResponse.payload.message);
    }

    // Handle address update
    await handleAddressUpdate(
      address_state,
      pickup_address_id,
      name,
      router,
      setEditStoreLoading,
      dispatch
    );

    // Push data layer event on success
    push_data_layer({
      event: 'edit_account',
      data: { name },
    });
    dispatch(clearAttributes());

    router.push('/a/my-account');
  } catch (error) {
    setShowError(true);
    setError_message(error.message);
    setEditStoreLoading(false);
  }
};
